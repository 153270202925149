import styled from 'styled-components';

export const SmartSupportExtendedButtonContainer = styled.div`
    background-color:  ${(props) => props.backgroundColor};
    padding: 0px 20px;
    border-radius: 0.75em;
    height:30px;
    font-size: 14px;
    margin-left: 8px;
    margin-right: 8px;
    align-self: center;
   ${(props) => props.customCss};
   color: ${(props) => props.textColor.textColor};
   display: flex;
   cursor: pointer;
    i {
        font-size: 1.7em !important;
        color: ${(props) => props.textColor.textColor};
        display: inline-block;
        margin-top: 4px;
        vertical-align: top;
    }
`;

export const SmartSupportExtendedButtonText = styled.span`
    padding-left: 10px;
    margin: auto;
    line-height: 30px;
    color: ${(props) => props.textColor.textColor};
    @media (max-width: 1150px) {
    display: none; }
`;
