/* eslint-disable max-len */
import { ProtectedRoute } from 'components/auth/protected-route.component';
import { lazy } from 'react';
import { LazyWrapper } from '../routes';

const PartsListLazy = lazy(() => import('routes/part/partsList/partsList.component'));
const PartLazy = lazy(() => import('routes/part/part/part.component'));
const PartSupplierListLazy = lazy(() => import('routes/part/part-supplier-list/part-supplier-list.component'));
const PartSupplierLazy = lazy(() => import('routes/part/supplier/supplier.component'));
const TechnicalReviewLazy = lazy(() => import('routes/part/technicalReview/technicalReview.component'));
const SemiconductorListLazy = lazy(() => import('routes/part/semiconductor-list/semiconductor-list.component'));
const SemiconductorReportsLazy = lazy(() => import('routes/part/semiconductor-reports/semiconductor-reports.component'));
const SemiconductorLazy = lazy(() => import('routes/part/semiconductor/semiconductor.component'));

export const partsRoutes = [
    // partsList
    { path: ':clientPrefix/part/partsList', component: <LazyWrapper><ProtectedRoute><PartsListLazy /></ProtectedRoute></LazyWrapper> },

    // partSupplier
    { path: ':clientPrefix/part/supplier/:id', component: <LazyWrapper><ProtectedRoute><PartSupplierLazy /></ProtectedRoute></LazyWrapper> },
    { path: ':clientPrefix/part/supplier', component: <LazyWrapper><ProtectedRoute><PartSupplierLazy /></ProtectedRoute></LazyWrapper> },

    // part
    { path: ':clientPrefix/part/part/:id', component: <LazyWrapper><ProtectedRoute><PartLazy /></ProtectedRoute></LazyWrapper> },
    { path: ':clientPrefix/part/part', component: <LazyWrapper><ProtectedRoute><PartLazy /></ProtectedRoute></LazyWrapper> },

    // partSupplierList
    { path: ':clientPrefix/part/supplier/list', component: <LazyWrapper><ProtectedRoute><PartSupplierListLazy /></ProtectedRoute></LazyWrapper> },

    // technicalReview
    { path: ':clientPrefix/part/technicalReview', component: <LazyWrapper><ProtectedRoute><TechnicalReviewLazy /></ProtectedRoute></LazyWrapper> },

    // semiconductors
    { path: ':clientPrefix/part/semiconductor/reports', component: <LazyWrapper><ProtectedRoute><SemiconductorReportsLazy /></ProtectedRoute></LazyWrapper> },
    { path: ':clientPrefix/part/semiconductor/list', component: <LazyWrapper><ProtectedRoute><SemiconductorListLazy /></ProtectedRoute></LazyWrapper> },
    { path: ':clientPrefix/part/semiconductor/:id', component: <LazyWrapper><ProtectedRoute><SemiconductorLazy /></ProtectedRoute></LazyWrapper> },
];
