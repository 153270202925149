import { useQuery } from 'react-query';

const TempKeySuffix = '-tempdata';

const getFlatTempQueryKey = (queryKey) => {
    if (typeof queryKey === 'object' && Array.isArray(queryKey)) {
        return queryKey[0] + queryKey[1];
    }

    return queryKey;
};

const buildTempKey = (flatKey) => `${flatKey}${TempKeySuffix}`;

export const nonExpiringQueryConfig = {
    refetchOnWindowFocus: false,
    refetchOnmount: true,
    refetchOnReconnect: false,
    retry: false,
    staleTime: Infinity,
    cacheTime: Infinity,
};

export const useMOTQuery = (config) => useQuery({
    refetchOnWindowFocus: false,
    refetchOnmount: true,
    refetchOnReconnect: false,
    retry: false,
    ...config,
});

export const initMOTData = (queryClient, queryKey, initialData) => {
    const tempKey = buildTempKey(getFlatTempQueryKey(queryKey));
    const tempData = queryClient.getQueryData(tempKey);

    if (tempData === undefined) {
        return initialData;
    }

    const newData = {
        ...initialData,
        ...tempData,
    };

    queryClient.removeQueries({ queryKey: tempKey });

    return newData;
};

export const setMOTQueryData = (queryClient, queryKey, updater) => {
    const originalData = queryClient.getQueryData(queryKey);

    if (originalData === undefined) {
        const tempKey = buildTempKey(getFlatTempQueryKey(queryKey));
        queryClient.setQueryData(tempKey, updater);
    } else {
        queryClient.setQueryData(queryKey, updater);
    }
};
