import { createSlice } from '@reduxjs/toolkit';

export const internalErrorSlice = createSlice({
  name: 'internalError',
  initialState: {
    show: false,
    serviceId: null,
  },
  reducers: {
    toggleInternalError: (state, action) => {
      const { show, serviceId } = action.payload;
      state.show = show;
      state.serviceId = serviceId;
    },
  },
});

export const { toggleInternalError } = internalErrorSlice.actions;
export default internalErrorSlice.reducer;
