import { clientLessPages } from 'app/clientless.pages';
import { appClientSelector } from 'store/app/app.selectors';

export const getClientPrefix = (state) => appClientSelector(state);

export const getClientPrefixFromUrl = () => window.location.pathname.split('/')[1].toLowerCase();

export const isClientPrefixValid = (state) => {
    const clientPrefix = getClientPrefix(state);
    if (clientPrefix) {
        return true;
    }

    const currentPathName = window.location.pathname.toLowerCase();
    const canClientPrefixBeFalse = clientLessPages.includes(currentPathName);
    if (canClientPrefixBeFalse) {
        return true;
    }

    return false;
};
