import styled from 'styled-components';

export const NavigationBarWrapper = styled.div`
    ${(props) => (props.anyViewInitialized ? '' : 'visibility: hidden;')}
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 11000000;
    user-select: none;
`;

export const NavigationBarPlaceholderWrapper = styled.div`
    height: ${(props) => props.height}px;
    position: static;
    @media print {
        display: none;
    }
`;
