import { useSelector } from 'react-redux';
import { showSpinnerSelector } from 'store/spinner/spinner.selectors';
import { commonTextSpinnerSelector, currentLanguageSelector } from 'store/translations/translations.selectors';
import {
 SpinnerContainer, SpinnerContent, SpinnerContentAnimation, SpinnerInner, SpinnerTextContainer,
} from './spinner.styles';

export const Spinner = ({ showAlways }) => {
    const userLang = useSelector(currentLanguageSelector);
    const waitTranslation = useSelector(commonTextSpinnerSelector(userLang));
    const show = useSelector(showSpinnerSelector);

    return (
        show || showAlways
        ? <SpinnerContainer>
            <SpinnerContent>
                <SpinnerContentAnimation>
                    <SpinnerInner/>
                </SpinnerContentAnimation>
                <div className='text-center'>
                    <SpinnerTextContainer>
                        {waitTranslation}
                    </SpinnerTextContainer>
                </div>
            </SpinnerContent>
        </SpinnerContainer>
        : null
    );
};
