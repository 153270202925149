import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { scrollTop } from 'utils/document';

export const ScrollTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    scrollTop();
  }, [pathname]);

  return null;
};
