import { recentlySelectedUrl } from 'components/app/navigationBar/urls';
import { getComboBoxText } from 'components/kendo/combo-box/combo-box.component';
import { setIds } from 'store/navigation-bar/navigation-bar.slice';
import MOTApi from 'utils/motApi';

const userSearchOnSelectItem = (id, urlBase) => {
    if (id) {
        const url = urlBase.replace('__ID__', id);
        document.location.replace(url);
    }
};

const librarySearchOnSelectItem = (id, urlBase) => {
    if (id) {
        const url = urlBase.replace('__ID__', id);
        document.location.replace(url);
    }
};

export const onChangeSearch = async (e, editUserUrl, documentPreviewUrl, dispatch, code) => {
    if (e.target && e.target.value && e.target.value.isSeparator) {
        return;
    }
    const { searchcode, rolloutProcessId } = e.target.props;
    const id = e.target?.value?.value;
    const saveRecentlySelected = async () => {
        await MOTApi.post(recentlySelectedUrl(), { rolloutProcessId, searchSectionCode: searchcode, id });
    };
    await saveRecentlySelected();

    if (searchcode === 'Settings_UsersSearch') {
        userSearchOnSelectItem(id, editUserUrl);
    } else if (searchcode === 'Library_Search') {
        librarySearchOnSelectItem(id, documentPreviewUrl);
    }

    dispatch(setIds({ code, id }));
};

export const onOpenSearch = (e, isInitialRef) => {
    if (isInitialRef.current) {
        if ((e.target.props.defaultText || '') !== getComboBoxText(e)) {
            return;
        }
        e.target.requestData(0, '');
        isInitialRef.current = false;
    }
};
