import { DropDownList } from 'components/kendo/drop-down-list/drop-down-list.component';
import { useChangeSupplierBU } from './supplier-change-bu-picker.queries';
import { SupplierChangeBUPickerWrapper } from './supplier-change-bu-picker.styles';

export const SupplierChangeBUPicker = ({
    menuSupplierBusinessUnits,
}) => {
    const changeBU = useChangeSupplierBU();
    return <SupplierChangeBUPickerWrapper>
            <DropDownList
                data-test-id='supplier-change-bu-picker'
                data={menuSupplierBusinessUnits.supplierBusinessUnits.dataSource}
                textField='text'
                dataItemKey='value'
                onChange={(e) => {
                    changeBU.mutate(e.target.value.value);
                }}
                value={menuSupplierBusinessUnits.selectedSupplierBusinessUnit}
            />
    </SupplierChangeBUPickerWrapper>;
};
