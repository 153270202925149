import { createSlice } from '@reduxjs/toolkit';

export const notFoundSlice = createSlice({
  name: 'notFound',
  initialState: {
    show: false,
  },
  reducers: {
    toggleNotFound: (state, action) => {
        state.show = action.payload;
    },
  },
});

export const { toggleNotFound } = notFoundSlice.actions;
export default notFoundSlice.reducer;
