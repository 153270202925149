import { Row, Col } from 'react-bootstrap';
import { ModuleColumn } from './module-column.component';

export const ModuleContent = ({
    module,
    mainAreaSectionHeaderColor,
    editUserUrl,
    documentPreviewUrl,
}) => (
        <Row>
            <Col xs={12} className='p-0'>
                <Row>
                    {module.columns.map((column, index) => <ModuleColumn
                        key={index}
                        color={mainAreaSectionHeaderColor}
                        column={column}
                        code={module.codeName}
                        editUserUrl={editUserUrl}
                        documentPreviewUrl={documentPreviewUrl}
                    />)}
                </Row>
            </Col>
        </Row>
    );
