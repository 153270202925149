import { createSlice } from '@reduxjs/toolkit';

export const footerSlice = createSlice({
  name: 'footer',
  initialState: {
    data: null,
  },
  reducers: {
    setFooter: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setFooter } = footerSlice.actions;
export default footerSlice.reducer;
