import { searchUrl } from 'components/app/navigationBar/urls';
import { ComboBox } from 'components/kendo/combo-box/combo-box.component';
import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import MOTApi from 'utils/motApi';
import {
    SearchSectionWrapper,
    SectionActionElementIcon,
    SectionComboboxWrapper,
    SectionHeader,
    SectionWrapper,
} from '../../menu.styles';
import { itemRender } from './section.templates';
import { onChangeSearch, onOpenSearch } from './section.utils';

export const SearchWithNameSection = ({
    section,
    color,
    editUserUrl,
    documentPreviewUrl,
    code,
 }) => {
    const comboboxRef = useRef(null);
    const isInitialRef = useRef(true);
    const divRef = useRef(null);

    const dispatch = useDispatch();
    const [width, setWidth] = useState(0);

    const onFocus = (e) => {
        // combobox in menu does not calculate width right on focus
        setWidth(e.target.base.wrapper.offsetWidth);
    };

    return (section.sectionItems.map((searchElement, index) => (<SectionWrapper key={index}>
        {
            searchElement.rolloutProcessId
            && <SectionHeader color={color}>
                {searchElement.name}
            </SectionHeader>
        }
        <SearchSectionWrapper className='d-flex align-items-center'>
            <SectionActionElementIcon fs={17} className='d-inline-block'>
                <i className={`${searchElement.iconFontAwesome}`}></i>
            </SectionActionElementIcon>
            <SectionComboboxWrapper ref={divRef} className='w-100'>
                <ComboBox // OBSOLETE USE ComboboxLocalData or ComboboxRemoteData | use when old minlength is forced and virtualization not available
                    onOpen={(e) => onOpenSearch(e, isInitialRef)}
                    onFocus={(e) => onFocus(e)}
                    onChange={(e) => onChangeSearch(e, editUserUrl, documentPreviewUrl, dispatch, code)}
                    searchcode={searchElement.code}
                    ref={comboboxRef}
                    autoBind={false}
                    postData={{
                        minSearchLetters: searchElement.searchMinLetterTrigger,
                        searchSectionCode: searchElement.code,
                        rollOutProcessId: searchElement.rolloutProcessId,
                    }}
                    popupSettings={{ appendTo: divRef.current, width }}
                    rolloutProcessId={searchElement.rolloutProcessId}
                    defaultText = {searchElement.defaultText}
                    valueField={'value'}
                    textField={'text'}
                    placeholder={searchElement.searchPlaceholder}
                    minLength={searchElement.searchMinLetterTrigger}
                    value={{ text: searchElement.defaultText || '', value: parseInt((searchElement.defaultValue || -1), 10) }}
                    itemRender={(li, liProps) => itemRender(li, liProps, searchElement.code)}
                    url={searchUrl(searchElement.searchAction)}
                    data={
                        searchElement.value
                        ? [{ text: searchElement.defaultText || '', value: parseInt((searchElement.defaultValue || -1), 10) }]
                        : []}
                />
            </SectionComboboxWrapper>
        </SearchSectionWrapper>
    </SectionWrapper>
    )));
};
