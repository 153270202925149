import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { initMenu, toggleNavbar } from 'store/navigation-bar/navigation-bar.slice';
import { setPageHeader } from 'store/page-header/page-header.slice';
import MOTApi from 'utils/motApi';
import { useQueryClient } from 'react-query';
import { commonTextTitleLoadingSelector } from 'store/translations/translations.selectors';
import { usePageTitle } from 'utils/document';
import { useShowSupportWidgetOnMobile } from 'app/support-widget/support-widget.component';

export const RouteWrapper = (props) => {
    const dispatch = useDispatch();
    const { useLayout, userLanguage } = props;
    const loadingSnippet = useSelector(commonTextTitleLoadingSelector(userLanguage));
    const title = loadingSnippet;
    const [setPageTitle] = usePageTitle();
    const [setShowSupportWidgetOnMobile] = useShowSupportWidgetOnMobile();

    useEffect(() => {
        dispatch(toggleNavbar(useLayout));
        dispatch(setPageHeader(''));
        setPageTitle(title);
    }, [dispatch, useLayout, title, setPageTitle]);

    useEffect(() => {
        if (userLanguage && useLayout) {
            dispatch(initMenu({ MOTApi, langCode: userLanguage }));
        }
    }, [dispatch, useLayout, userLanguage]);

    const queryClient = useQueryClient();

    useEffect(() => {
        queryClient.clear();
    }, [queryClient]);

    useEffect(() => {
        setShowSupportWidgetOnMobile();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return props.children;
};
