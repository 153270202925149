import styled, { createGlobalStyle } from 'styled-components';

export const LogoContainer = styled.div`
    margin-left: 15px;
    cursor: pointer;
    ${(props) => ((!props.isLoggedIn || props.isDummy) ? 'pointer-events: none; cursor: auto;' : '')} 
    img {
        object-fit: contain;
        width: ${(props) => props.width};
        height: ${(props) => props.height};
    }
`;

export const CaptionTextContainer = styled.div`
    ${(props) => props.customStyle}
`;

export const HorizontalBarGlobalStyle = createGlobalStyle`
    .navigation-dropdown-popup {
        .k-list {
            background-color: #f2f2f2;
            ul li:hover {
                background-color: rgba(0, 0, 0, 0.2) !important;
            }
            .k-focus, .k-selected {
                background-color: rgba(0, 0, 0, 0.2) !important;
            }
        }
    }
`;

export const HorizontalBarRightItemsLastContainer = styled.div`
    display:inline-block;
    margin-left: 5px;
`;

export const HorizontalBarRightItemsContainer = styled.div`
    display: flex;
    align-items: center;
    line-height: normal;
    margin-left: auto;
    i {
        font-size: 15px;
        color: ${(props) => props.horizontalBarIconColor};
    }
`;

export const HorizontalBarContainer = styled.div`
    background-color: ${(props) => props.backgroundColor};
    color: ${(props) => props.color};
    line-height: 42px;
    padding: 5px 10px 5px 10px;
    display: flex;
    align-items: center;

    .k-dropdownlist button {
        display: none;
    }
    ${(props) => (props.mainAreaTitleHeaderStyle ? `${props.mainAreaTitleHeaderStyle}` : '')}
`;
