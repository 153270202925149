import { colorNames } from 'app/app.styles';
import styled from 'styled-components';

export const SpinnerContainer = styled.div`
  z-index: 13000001;
  position: fixed;
  left: 45%;
  top: 45%;
`;

export const SpinnerContent = styled.div`
  min-width: 160px;
  height: 75px;
  background-color: #f2f2f2;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const SpinnerContentAnimation = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
`;

export const SpinnerInner = styled.span`
  width: 108px;
  height: 16px;
  background:
  radial-gradient(circle 8px at 8px center, #AAA 100%, transparent 0),
  radial-gradient(circle 8px at 8px center, #CCC 100%, transparent 0);
  background-size: 16px 16px;
  background-repeat: no-repeat;
  position: relative;
  animation: ballX 1s linear infinite;

  &:before {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: var(${colorNames.ZenCyan});
    inset:0;
    margin:auto;
    animation: moveX 1s cubic-bezier(0.5,300,0.5,-300) infinite;
  }

  @keyframes ballX {
    0%,25%,50%,75%, 100%  {background-position: 25% 0,75% 0}
    40% {background-position: 25% 0,85% 0}
    90% {background-position: 15% 0,75% 0}
  }

  @keyframes moveX {
    100% {transform:translate(0.15px)}
  }
`;

export const SpinnerTextContainer = styled.div`
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 16px;
  line-height: 1.2em;
  color: #444;
  padding: 0px 15px;
`;
