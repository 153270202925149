import { ComboBox as KendoComboBox } from '@progress/kendo-react-dropdowns';
import {
    useCallback,
    forwardRef,
    useEffect,
    useRef,
} from 'react';
import { useSelector } from 'react-redux';
import { commonPlaceholderNLettersSelector, commonPleaseSelectSelector, currentLanguageSelector } from 'store/translations/translations.selectors';
import { subscribe, unsubscribe } from 'utils/events';
import { getDescendantProp } from 'utils/string';
import { ComboBoxGlobalStyles, ComboBoxWrapper } from './combo-box.styles';

let isDisabledSelected;

export const ComboBoxBase = forwardRef((props, ref) => {
    const {
        isCustomValueTemplate,
        onChange,
        isLocalData,
        typeInLetters,
    } = props;

    let {
        placeholder,
    } = props;

    const userLang = useSelector(currentLanguageSelector);
    const pleaseTypeInSnippet = useSelector(commonPlaceholderNLettersSelector(userLang, typeInLetters));
    const pleaseSelectSnippet = useSelector(commonPleaseSelectSelector(userLang));

    if (typeInLetters === 0) {
        placeholder = pleaseSelectSnippet;
    } else if (typeInLetters > 0) {
        placeholder = pleaseTypeInSnippet;
    }

    const backupRef = useRef(null);
    const onRefChange = (node) => {
        const localRef = ref || backupRef;
        if (typeof localRef === 'function') {
            localRef(node);
        } else {
            // eslint-disable-next-line no-param-reassign
            ref.current = node;
        }
        if (node) {
            backupRef.current = node;
        }
    };

    const toggleIsOpen = useCallback(() => {
        if (backupRef?.current?.element?.querySelector('button')?.click) {
            backupRef.current.element.querySelector('button').click();
        }
    }, [backupRef]);

    useEffect(() => {
        const input = backupRef?.current?.element.querySelector('input');
        if (backupRef?.current?.element.querySelector('input')) {
            subscribe(input, 'click', toggleIsOpen);
        }

        return () => {
            unsubscribe(input, 'click', toggleIsOpen);
        };
    }, [backupRef, toggleIsOpen]);

    let { itemRender } = props;

    let onClose = (e) => {
        if (props.onClose) {
          props.onClose(e);
        }
    };

    const onOpen = (e) => {
        if (props.onOpen) {
          props.onOpen(e);
        }
    };
    const onFocus = (e) => {
        if (props.onFocus) {
            props.onFocus(e);
        }
    };

    if (props.itemDisabled) {
        const disabledItemRender = (itemTemplate, li, itemProps, disabledSelector) => {
            let disabledProps = li.props;
            const isDisabled = getDescendantProp(itemProps.dataItem, disabledSelector);
            if (isDisabled) {
                disabledProps = { ...disabledProps, className: `${disabledProps.className} drop-down-disabled-item` };
            }
            const itemChildren = itemTemplate(li, disabledProps, itemProps);

            return itemChildren;
        };

        const onCloseDisabled = (e, onCloseF) => {
            if (isDisabledSelected) {
              return;
            }

            if (onCloseF) {
              onCloseF(e);
            }
        };

        onClose = (e) => onCloseDisabled(e, props.onClose);
        itemRender = (li, itemProps) => disabledItemRender(props.itemRender, li, itemProps, props.itemDisabled);
    }

    const onChangeComboBox = useCallback((event) => {
        if (props.itemDisabled) {
            const isDisabled = event.value && getDescendantProp(event.value, props.itemDisabled);
            if (isDisabled) {
                isDisabledSelected = true;
                setTimeout(() => {
                    isDisabledSelected = false;
                });
            }

            if (!event.value || !isDisabled || isLocalData) {
                if (onChange) {
                    onChange(event);
                }
            }
        } else if (onChange) {
            onChange(event);
        }
    }, [isLocalData, onChange, props.itemDisabled]);

    return <ComboBoxWrapper isCustomValueTemplate={isCustomValueTemplate}>
        <ComboBoxGlobalStyles/>
        <KendoComboBox
            ref={onRefChange}
            {...props}
            onChange={(onChangeComboBox)}
            onOpen={onOpen}
            onClose={onClose}
            onFocus={onFocus}
            itemRender={itemRender}
            placeholder={placeholder}
        />
    </ComboBoxWrapper>;
});
