import { useState, useRef, useEffect } from 'react';
import { Window } from 'components/kendo/window/window.component';
import { useDispatch, useSelector } from 'react-redux';
import { setSignOut } from 'store/user/user.slice';
import { Icon } from 'components/icon/icon.component';
import { topOffsetNavigationBarSelector } from 'store/navigation-bar/navigation-bar.selectors';
import { subscribe, unsubscribe } from 'utils/events';
import {
    UserProfileMenuFirstName,
    UserProfileMenuGlobalStyle,
    UserProfileMenuHeaderWrapper,
    UserProfileMenuItemWrapper,
    UserProfileMenuLastName,
    UserProfileMenuWrapper,
} from './user-profile-menu.styles';
import { HorizontalMenuIcon } from '../horizontal-menu-icon.component';
import { UserProfileMenuLanguage } from './user-profile-menu-language.component';

export const UserProfileMenu = ({
    textColor,
    firstName,
    lastName,
    elements,
    mainAreaSectionElementFontColor,
    horizontalBarBackGroundCalc,
    mainAreaBackgroundColor,
    isLoggedIn,
    isDummy,
}) => {
    const top = useSelector(topOffsetNavigationBarSelector);
    const [showMenu, setShowMenu] = useState(false);
    const windowRef = useRef(null);

    useEffect(() => {
        const windowElem = windowRef?.current?.element;
        const f = (e) => {
            setTimeout(() => {
                if ((e.relatedTarget?.classList && e.relatedTarget.classList.contains('user-profile-menu-language-picker'))
                || (e.relatedTarget?.classList && e.relatedTarget.classList.contains('user-profile-menu-link'))) {
                    return;
                }
                setShowMenu(false);
            }, 120);
        };
        subscribe(window, 'focusout', f);

        return () => unsubscribe(windowElem, 'focusout', f);
    }, [windowRef]);

    useEffect(() => {
        if (showMenu) {
            const elem = windowRef?.current?.element;
            if (elem) {
                elem.focus();
            }
        }
    }, [showMenu]);

    const dispatch = useDispatch();
    return <>
        <UserProfileMenuWrapper onClick={() => elements.length > 0 && setShowMenu(true)}>
        <div className='d-lg-inline-block me-1 d-none'>
            <UserProfileMenuFirstName textColor={textColor}>{firstName}</UserProfileMenuFirstName>
            <UserProfileMenuLastName textColor={textColor}>{lastName}</UserProfileMenuLastName>
        </div>
        <HorizontalMenuIcon textColor={textColor} icon='fa-regular fa-circle-user'/>
    </UserProfileMenuWrapper>
    {
        showMenu && <>
            <UserProfileMenuGlobalStyle
                top={top}
                mainAreaBackgroundColor={mainAreaBackgroundColor}
                horizontalBarBackGroundCalc={horizontalBarBackGroundCalc}
            />
            <Window
                ref={windowRef}
                className='user-profile-menu-window'
            >
                {
                    elements.map((elem, i) => {
                        if (elem.isLanguageSection) {
                            return <UserProfileMenuLanguage
                                key={i}
                                mainAreaSectionElementFontColor={mainAreaSectionElementFontColor}
                                elem={elem}
                                isLoggedIn={isLoggedIn}
                                setShowMenu={setShowMenu}
                                isDummy={isDummy}
                            />;
                        }

                        if (elem.isLogoutSection && !elem.isLanguageSection) {
                            return <UserProfileMenuItemWrapper
                                clickable={true}
                                spacer={elem.spacer}
                                key={i}
                                mainAreaSectionElementFontColor={mainAreaSectionElementFontColor}
                            >
                                <UserProfileMenuHeaderWrapper>
                                    <span onClick={() => {
                                            dispatch(setSignOut(true));
                                        }}
                                        className='clickable user-profile-menu-link' aria-hidden={true}
                                    >
                                        <Icon
                                            className='common-icon'
                                            icon={elem.icon}
                                        />{elem.name}
                                    </span>
                                </UserProfileMenuHeaderWrapper>
                            </UserProfileMenuItemWrapper>;
                        }

                        return <UserProfileMenuItemWrapper
                            spacer={elem.spacer}
                            clickable={true}
                            mainAreaSectionElementFontColor={mainAreaSectionElementFontColor}
                            key={i}
                        >
                            <UserProfileMenuHeaderWrapper>
                                    <a href={elem.url} className='user-profile-menu-link' aria-hidden={true}>
                                        <Icon
                                            className='common-icon'
                                            icon={elem.icon}
                                        />{elem.name}
                                    </a>
                                </UserProfileMenuHeaderWrapper>
                        </UserProfileMenuItemWrapper>;
                    })
                }
            </Window>
        </>
    }
</>;
};
