import { colorNames } from 'app/app.styles';
import styled from 'styled-components';

export const PageHeaderContainer = styled.div`
    -webkit-print-color-adjust: exact;
    background-color: var(${colorNames.ZenMenuDarkGreyBg});
    text-align: left;
    padding: 9px 15px 9px 15px;
    word-wrap: break-word;
    color: var(${colorNames.ZenFontMenuDarkGreyBg});
`;

export const PageHeaderText = styled.div`
    margin-left: 5px;
    font-size: 11pt;
    color: var(${colorNames.ZenFontMenuDarkGreyBg});
    text-transform: uppercase;
    font-family: Franklin Gothic Book,Calibri,Verdana,Arial;
`;
