import { ProtectedRoute } from 'components/auth/protected-route.component';
import { lazy } from 'react';
import { LazyWrapper } from '../routes';

const BankRequestLazy = lazy(() => import('routes/suppliers/masterData/bank/bankRequest.component'));
const BankEnrichmentRequestLazy = lazy(() => import('routes/suppliers/masterData/bankEnrichment/bankEnrichmentRequest.component'));

export const suppliersRoutes = [
    // bank request
    {
        path: ':clientPrefix/suppliers/masterData/bank/:id',
        component: <LazyWrapper><ProtectedRoute><BankRequestLazy/></ProtectedRoute></LazyWrapper>,
    },

    {
        path: ':clientPrefix/suppliers/masterData/bankEnrichment/:id',
        component: <LazyWrapper><ProtectedRoute><BankEnrichmentRequestLazy/></ProtectedRoute></LazyWrapper>,
    },
];
