import styled, { createGlobalStyle } from 'styled-components';
import { Col } from 'react-bootstrap';
import { colorNames } from 'app/app.styles';

export const GlobalStyle = createGlobalStyle`
  body, html {
    background-color: var(${colorNames.ZenCyan});
    height: 100%;
    font-family: Arial, Verdana, Sans-Serif;
  }

  #root { 
    height: 100%;
  }

h2 {
    margin-left: 0;
    padding-left: 0;
    font-size: 46px;
    font-weight: bold;
}

h2, p, li {
    color: var(${colorNames.ZenFontCyan});
}

p, li {
    font-size: 22px;
}

li {
    line-height: 180%;
}

a {
    text-decoration: none;
    color: var(${colorNames.ZenFontCyan}) !important;
}

a.error-underline {
    border-bottom: 1px solid;
}`;

export const ColContentImage = styled(Col)`
    display: flex;
    align-items: flex-end;
`;

export const ColContentInfo = styled(Col)`
    display: flex;
    align-items: center;
`;

export const NotFoundPageContainer = styled.div`
    height: 100%;
`;

export const ErrorPageImageContainer = styled.div`
    text-align: center;
    margin: 0 auto;

    img {
        width: 80%;
    }
    
    @media (max-width: 991px) {
        img {
            width: 30%;
        }
        margin: 25px 0 0 0;
    }
`;

export const ErrorPageInfoContainer = styled.div`
    margin: 40px 0 0 0;
    color: var(${colorNames.ZenFontCyan});
`;
