import { createSlice } from '@reduxjs/toolkit';

export const spinnerSlice = createSlice({
    name: 'spinner',
    initialState: {
        show: true,
    },
    reducers: {
        toggleSpinnerAction: (state, action) => {
            state.show = action.payload;
        },
    },
});

const { toggleSpinnerAction } = spinnerSlice.actions;
export default spinnerSlice.reducer;

export const toggleSpinner = (value, dispatch) => {
    setTimeout(() => {
        dispatch(toggleSpinnerAction(value));
    }, 0);
};
