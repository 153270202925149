import { Spinner } from 'components/spinner/spinner.component';
import { nonExpiringQueryConfig, useMOTQuery } from 'hooks/useMOTQuery';
import { logError } from 'utils/logger';
import { useQueryClient } from 'react-query';
import { DropDownList } from './drop-down-list.component';

export const useUpdateDropDownListWithQuery = () => {
    const queryClient = useQueryClient();

    const updateDropDownListWithQuery = (key, newValue) => {
        queryClient.setQueryData(key, newValue);
    };

    return [updateDropDownListWithQuery];
};

export const DropDownListWithQuery = (props) => {
    if (!props.queryKey) {
        logError('missing query key');
    }

    const query = useMOTQuery({
        queryKey: props.queryKey,
        initialData: props.value,
        ...nonExpiringQueryConfig,
    });

    if (query.isLoading) {
        return <Spinner showAlways={true} />;
    }

    return <DropDownList
        {...props}
        useQuery={true}
        queryKey={props.queryKey}
        value={query.data}
    />;
};
