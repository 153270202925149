import { useSelector } from 'react-redux';
import { commonTextNo, commonTextYes, currentLanguageSelector } from 'store/translations/translations.selectors';
import { Dialog } from '../dialog.component';

export const YesNoDialog = (props) => {
    const userLang = useSelector(currentLanguageSelector);
    const yes = useSelector(commonTextYes(userLang));
    const no = useSelector(commonTextNo(userLang));
    const {
        isVisible,
        yesAction,
        noAction,
        text,
    } = props;

    const buttons = [
        {
            buttonId: 'yes',
            title: yes,
            text: yes,
            primary: true,
            onClick: () => {
                yesAction();
            },
        },
        {
            buttonId: 'no',
            title: no,
            text: no,
            primary: false,
            onClick: () => {
                noAction();
            },
        },
    ];

    return (
        isVisible
        && <Dialog
            {...props}
            data-test-id='yesno'
            onClose={() => noAction()}
            buttons={buttons}>
                <div>{text}</div>
            </Dialog>
    );
};
