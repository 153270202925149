import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { appInitUrl, preInitUrl } from 'app/urls';
import MOTApi from 'utils/motApi';
import { initLang } from 'store/translations/translations.slice';
import { setCurrentUser } from 'store/user/user.slice';
import { getClientPrefixFromUrl } from 'app/clientPrefix';
import { initClientPrefix } from 'app/clientPrefix.initializer';
import { setDefaults } from 'store/defaults/defaults.slice';
import { prepareMenu, setMenuData } from 'store/navigation-bar/navigation-bar.slice';
import { setControls } from 'store/controls/controls.slice';
import { toggleSpinner } from 'store/spinner/spinner.slice';
import { setFooter } from 'store/footer/footer.slice';
import { appInitializedSelector } from './app.selectors';
import { initStyles } from '../../app/app.styles';

const INITIAL_STATE = {
    appInitialized: false,
    clients: [],
    clientName: null,
};

export const initializeClient = (clientNames, clientPrefix, dispatch) => {
  const isClientPrefixValid = clientNames.includes(clientPrefix);
  const clientPayload = {
    clientNames,
    clientName: null,
  };

  if (isClientPrefixValid) {
    initClientPrefix(clientPrefix, clientNames);
    clientPayload.clientName = clientPrefix;
  }
  dispatch(initClients(clientPayload));
};

const initializeClients = (clientNames, dispatch) => {
  const clientPrefix = getClientPrefixFromUrl();
  initializeClient(clientNames, clientPrefix, dispatch);
};

export const initApp = createAsyncThunk('app/initialize', async ({ setTranslations }, thunkApi) => {
  const { dispatch } = thunkApi;

  const preinitData = (await MOTApi.get(preInitUrl())).data;
  initializeClients(preinitData.clientNames, dispatch);

  const initData = (await MOTApi.get(appInitUrl())).data;
  initStyles(initData.styles);
  dispatch(setCurrentUser(initData.user));
  dispatch(setDefaults(initData.defaults));
  dispatch(initLang({ lang: initData.user.language, commonTranslations: initData.commonTranslations }));
  dispatch(setControls(initData.controls));
  dispatch(setFooter(initData.footer));

  setTranslations((prev) => ({
        ...prev,
        [initData.user.language]: initData.commonTranslations,
  }));
  const menu = await prepareMenu(initData.menu);
  dispatch(setMenuData(menu));
  toggleSpinner(false, dispatch);

  return true;
},
{
  condition: (obj, { getState }) => {
    if (appInitializedSelector(getState())) {
      return false;
    }
    return true;
  },
});

export const appSlice = createSlice({
    name: 'app',
    initialState: INITIAL_STATE,
    reducers: {
      setClient: (state, action) => {
        state.clientName = action.payload;
      },
      initClients: (state, action) => {
        const { clientNames, clientName } = action.payload;
        state.clients = clientNames;
        state.clientName = clientName;
      },
    },
    extraReducers: (builder) => {
      builder.addCase(initApp.fulfilled, (state) => {
        state.appInitialized = true;
      });
      builder.addCase(initApp.pending, (state) => {
        state.appInitialized = false;
      });
      builder.addCase(initApp.rejected, (state) => {
        state.appInitialized = true;
      });
    },
});

export const { setClient, initClients } = appSlice.actions;
export default appSlice.reducer;
