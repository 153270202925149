import { ProtectedRoute } from 'components/auth/protected-route.component';
import { lazy } from 'react';
import { LazyWrapper } from '../routes';

const ResourcesAssignmentLazy = lazy(() => import('routes/prm/resourcesAssignment/resourcesAssignment.component'));
const ResourceGapAnalysisLazy = lazy(() => import('routes/prm/resource-gap-analysis/resource-gap-analysis.component'));

export const prmRoutes = [
    // resources Assignment
    {
        path: ':clientPrefix/prm/resourcesAssignment/',
        component: <LazyWrapper><ProtectedRoute><ResourcesAssignmentLazy/></ProtectedRoute></LazyWrapper>,
    },
    // resource gap analysis
    {
        path: ':clientPrefix/prm/gapAnalysis/',
        component: <LazyWrapper><ProtectedRoute><ResourceGapAnalysisLazy/></ProtectedRoute></LazyWrapper>,
    },
];
