import { lazy, Suspense } from 'react';
import { Spinner } from 'components/spinner/spinner.component';
import NotFound from 'components/not-found/not-found.component';

export const LazyWrapper = ({ children }) => <Suspense fallback={<Spinner showAlways={true}/>}>{children}</Suspense>;

const NotFoundLazy = lazy(() => import('components/not-found/not-found.component'));
export const NotFoundLazyWrapper = () => <LazyWrapper><NotFoundLazy /></LazyWrapper>;

const SignOutCallbackLazy = lazy(() => import('components/auth/signout/signOut-callback.component'));
const AuthSignInCallbackLazy = lazy(() => import('components/auth/signin/signIn-callback.component'));
const SignOutByUrlLazy = lazy(() => import('components/auth/signout/signOut-byUrl.component'));

export const routes = [
    // account
    { path: 'account/signOutCallback', component: <LazyWrapper><SignOutCallbackLazy/></LazyWrapper> },
    { path: ':clientPrefix/account/signInCallback', component: <LazyWrapper><AuthSignInCallbackLazy/></LazyWrapper> },
    { path: ':clientPrefix/account/signOut', component: <LazyWrapper><SignOutByUrlLazy/></LazyWrapper> },

    { path: '*', component: <NotFound/> },
];
