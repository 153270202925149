import {
    SectionActionElement,
    SectionActionElementIcon,
    SectionHeader,
    SectionWrapper,
} from '../../menu.styles';

export const LinkGroupSection = ({ section, color }) => (
    <SectionWrapper>
        <SectionHeader color={color}>
            {section.name}
        </SectionHeader>
        <div>
            {
                section.sectionItems.map((sectionItem, index) => <SectionActionElement key={index}>
                    <SectionActionElementIcon className='d-inline-block'>
                        <i className={`${sectionItem.iconFontAwesome}`}></i>
                    </SectionActionElementIcon>
                    <span>
                        <a href={sectionItem.url}>{sectionItem.name}</a>
                    </span>
                </SectionActionElement>)
            }
        </div>
    </SectionWrapper>
);
