import { Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { selectModule } from 'store/navigation-bar/navigation-bar.slice';
import MOTApi from 'utils/motApi';
import { SubMenuItemHeaderWrapper, SubMenuItemIconWrapper, SubMenuItemWrapper } from '../menu.styles';

export const SubMenuItem = ({
    module,
    isActive,
    icons,
    verticalBarIconPadding,
    verticalMenuIconColour,
    verticalMenuTextColour,
    verticalBarIconWidth,
    verticalBarIconHeight,
    verticalBarIconOnHooverBackgroundColor,
    clientLogoHref,
    useTrippleBlockVerticalBar,
    useDuoBlockVerticalBar,
    useSingleBlockVerticalBar,
}) => {
    const dispatch = useDispatch();
    const onSubMenuItemClick = () => {
        dispatch(selectModule({ MOTApi, module: module.codeName }));
    };

    const moveToHomePage = () => {
        document.location.href = clientLogoHref;
    };

    let md = 6;
    let lg = 6;

    if (useTrippleBlockVerticalBar) {
        lg = 4;
        md = 6;
    } else if (useDuoBlockVerticalBar) {
        lg = 6;
        md = 6;
    } else if (useSingleBlockVerticalBar) {
        md = 12;
        lg = 12;
    }

    return (<Col className='clickable p-0' lg={lg} md={md} xs={12}>
        <SubMenuItemWrapper onClick={() => {
                if (module.codeName === 'home') {
                    moveToHomePage();
                } else {
                    onSubMenuItemClick();
                }
            }}
            padding={verticalBarIconPadding}
            isActive={isActive}
            verticalBarIconOnHooverBackgroundColor={verticalBarIconOnHooverBackgroundColor}>
            <SubMenuItemIconWrapper
                color={verticalMenuIconColour}
                width={verticalBarIconWidth}
                height={verticalBarIconHeight}
            >
                <div dangerouslySetInnerHTML={{ __html: icons[module.codeName] }}></div>
            </SubMenuItemIconWrapper>
            <SubMenuItemHeaderWrapper
                color={verticalMenuTextColour}>
                {module.iconTitle}
            </SubMenuItemHeaderWrapper>
        </SubMenuItemWrapper>
    </Col>);
};
