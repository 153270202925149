import styled from 'styled-components';

export const SmartSupportButtonContainer = styled.div`
   cursor: pointer;
   margin-left: 5px;
   margin-right: 5px;
    i {
        font-size: 2.5em !important;
        color: ${(props) => props.textColor};
    }
`;
