import MOTApi from 'utils/motApi';
import { bootstrapBreakpoints } from 'app/App.utils';
import { useWindowResize } from 'hooks/useWindowResize';
import {
    useState,
    useEffect,
    useCallback,
    useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    showNavigationBarSelector,
    menuSelector,
    topOffsetNavigationBarSelector,
} from 'store/navigation-bar/navigation-bar.selectors';
import { selectModule } from 'store/navigation-bar/navigation-bar.slice';
import { subscribe, unsubscribe } from 'utils/events';
import { anyViewInitializedSelector } from 'store/app/appSetter.Selectors';
import { HorizontalBar } from './horizontal-bar/horizontal-bar.component';
import { SubMenu } from './menu/submenu/sub-menu.component';
import {
    NavigationBarPlaceholderWrapper,
    NavigationBarWrapper,
} from './navigation-bar.styles';

const minMdBootstrap = bootstrapBreakpoints.md;
const minLgBootstrap = bootstrapBreakpoints.lg;

const getOffsetByWidth = (width) => {
    if (minLgBootstrap < width) {
        return 3;
    }

    if (minMdBootstrap < width) {
        return 2;
    }

    return 1;
};

const NavigationBarPlaceholder = () => {
    const height = useSelector(topOffsetNavigationBarSelector);
    return <NavigationBarPlaceholderWrapper height={height}></NavigationBarPlaceholderWrapper>;
};

export const NavigationBar = (props) => {
    const { userLanguage } = props;
    const menu = useSelector(menuSelector) || {};
    const {
        clientLogoMaxHeight,
        horizontalBarIconColor,
        customColors,
        customTextColor,
        verticalBarIconPadding,
        verticalBarIconHeight,
        verticalBarIconWidth,
        verticalBarFontColor,
        verticalBarBackgroundColor,
        verticalBarIconOnHooverBackgroundColor,
        mainAreaBackgroundColor,
        mainAreaTitleColor,
        mainAreaSectionHeaderColor,
        clientLogoHref,
    } = menu?.baseSettings || {};

    const {
        useTrippleBlockVerticalBar,
        useDuoBlockVerticalBar,
        useSingleBlockVerticalBar,
        module,
        editUserUrl,
        documentPreviewUrl,
    } = menu || {};

    const anyViewInitialized = useSelector(anyViewInitializedSelector);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const modules = useMemo(() => menu.modules || [], [menu.timestamp]);
    const textColor = customColors ? customTextColor : horizontalBarIconColor;
    const show = useSelector(showNavigationBarSelector) && clientLogoMaxHeight !== undefined;
    const [showSubMenu, setShowSubMenu] = useState(false);
    const selectedMenuOrder = modules.find((x) => x.codeName === module)?.order || 0;
    const dispatch = useDispatch();
    const { width } = useWindowResize();
    const onArrowKeyClick = useCallback((e, newWidth, showSubMenuL, modulesL, selectedMenuOrderL) => {
        let { keyCode } = e;
        const validKeys = [37, 38, 39, 40, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58];
        const isFocusedOnInput = document.activeElement.role === 'combobox';
        if (!showSubMenuL || !validKeys.includes(keyCode) || isFocusedOnInput) {
            return;
        }
        const maxOrder = modulesL.length - 1;

        let newSelectedMenuOrder;
        if (keyCode === 37) {
            const leftChange = -1;
            newSelectedMenuOrder = selectedMenuOrderL + leftChange;
        } else if (keyCode === 39) {
            const rightChange = 1;
            newSelectedMenuOrder = selectedMenuOrderL + rightChange;
        } else if (keyCode === 40) {
            const downChange = getOffsetByWidth(newWidth);
            newSelectedMenuOrder = selectedMenuOrderL + downChange;
        } else if (keyCode === 38) {
            const upChange = getOffsetByWidth(newWidth);
            newSelectedMenuOrder = selectedMenuOrderL - upChange;
        }
        const magicNumber = 48; // 0 keycode is 48
        if (keyCode > 47 && keyCode < 59) {
            if (keyCode === 58) {
                keyCode = 48;
            }
            if (keyCode === 48) {
                keyCode = 58;
            }
            const shiftOffset = e.shiftKey ? 10 : 0;
            newSelectedMenuOrder = shiftOffset + (keyCode - magicNumber) - 1;
        }

        if (newSelectedMenuOrder > maxOrder) {
            newSelectedMenuOrder %= maxOrder + 1;
        } else if (newSelectedMenuOrder < 0) {
            newSelectedMenuOrder = maxOrder + newSelectedMenuOrder + 1;
        }
        const moduleCodeName = modulesL.find((x) => x.order === (newSelectedMenuOrder || 0))?.codeName;
        dispatch(selectModule({ MOTApi, module: moduleCodeName }));
    }, [dispatch]);

    useEffect(() => {
        const f = (e) => onArrowKeyClick(e, width, showSubMenu, modules, selectedMenuOrder);
        subscribe(document, 'keydown', f);

        return () => unsubscribe(document, 'keydown', f);
    }, [width, showSubMenu, modules, selectedMenuOrder, onArrowKeyClick]);

    const onMenuIconClick = () => {
        setShowSubMenu((prev) => {
            document.querySelector('html').style.overflow = !prev ? 'hidden' : 'auto';
            return !prev;
        });
    };

    useEffect(() => {
        const triggerMenuOnKey = (event) => {
            const isAnyDropdownOpened = document.querySelectorAll('div.k-popup.k-list-container .k-list').length > 0;
            const isAnyDialogVisible = document.querySelectorAll('div.k-dialog-wrapper').length > 0;
            if (!isAnyDropdownOpened && !isAnyDialogVisible && event.key === 'Escape') {
                onMenuIconClick();
            }
        };

        subscribe(document, 'keydown', triggerMenuOnKey);
        return () => {
            unsubscribe(document, 'keydown', triggerMenuOnKey);
        };
    }, []);

    return show && <>
        <NavigationBarPlaceholder />
        <NavigationBarWrapper anyViewInitialized={anyViewInitialized}>
            <HorizontalBar
                userLanguage={userLanguage}
                show={show}
                menu={menu}
                textColor={textColor}
                onMenuIconClick={onMenuIconClick}
            />
            {<SubMenu
                showSubMenu={showSubMenu}
                selectedModule={module}
                closeMenu={onMenuIconClick}
                isVisible={showSubMenu}
                modules={modules}
                verticalBarIconOnHooverBackgroundColor={verticalBarIconOnHooverBackgroundColor}
                verticalBarIconHeight={verticalBarIconHeight}
                verticalBarIconWidth={verticalBarIconWidth}
                verticalMenuIconColour={verticalBarFontColor}
                verticalMenuTextColour={verticalBarFontColor}
                verticalBarIconPadding={verticalBarIconPadding}
                verticalBarBackgroundColor={verticalBarBackgroundColor}
                useTrippleBlockVerticalBar={useTrippleBlockVerticalBar}
                useDuoBlockVerticalBar={useDuoBlockVerticalBar}
                useSingleBlockVerticalBar={useSingleBlockVerticalBar}
                mainAreaBackgroundColor={mainAreaBackgroundColor}
                mainAreaTitleColor={mainAreaTitleColor}
                mainAreaSectionHeaderColor={mainAreaSectionHeaderColor}
                documentPreviewUrl={documentPreviewUrl}
                editUserUrl={editUserUrl}
                clientLogoHref={clientLogoHref}
            />}
        </NavigationBarWrapper>
    </>;
};
