import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setTopOffset } from 'store/navigation-bar/navigation-bar.slice';
import { DownloadFileUrl } from 'routes/files/urls';
import { currentUserDummySelector, currentUserLoggedInSelector } from 'store/user/user.selector';
import { LogoutDialog } from './logout-button/logout-dialog.component';
import { LanguagePicker } from './language-picker/language-picker.component';
import { SmartSupportButton } from './smartsupp-button/smartsupp-button.component';
import { SmartSupportExtendedButton } from './smartsupp-extended-button/smartsupp-extended-button.component';
import {
    CaptionTextContainer,
    HorizontalBarContainer,
    HorizontalBarGlobalStyle,
    HorizontalBarRightItemsContainer,
    LogoContainer,
    HorizontalBarRightItemsLastContainer,
} from './horizontal-bar.styles';
import { LogoutButton } from './logout-button/logout-button.component';
import { MenuIcon } from '../menu/menuIcon.component';
import { UserProfileMenu } from './user-profile-menu/user-profile-menu.component';
import { SupplierChangeBUPicker } from './supplier-change-bu-picker/supplier-change-bu-picker.component';

export const HorizontalBar = ({
    show,
    menu,
    userLanguage,
    textColor,
    onMenuIconClick,
}) => {
    const ref = useRef();
    const dispatch = useDispatch();
    const isLoggedIn = useSelector(currentUserLoggedInSelector);
    const isDummy = useSelector(currentUserDummySelector);

    const [isLogoutDialogVisible, setLogoutDialogVisible] = useState(false);
    const {
        clientLogoMaxWidth,
        clientLogoMaxHeight,
        clientCaptionText,
        clientCaptionStyle,
        customColors,
        customBackgroundColor,
        horizontalBarBackgroundColor,
        mainAreaSectionElementFontColor,
        clientLogoHref,
        mainAreaBackgroundColor,
        mainAreaTitleHeaderStyle,
        showChangeSupplierBU,
        clientLogoURL,
    } = menu?.baseSettings || {};
    const horizontalBarBackGroundCalc = customColors ? customBackgroundColor : horizontalBarBackgroundColor;
    const { languages, firstName, lastName } = menu?.userData || {};
    const { customLogoGUID, userProfileSections, menuSupplierBusinessUnits } = menu || {};

    useEffect(() => {
        let topOffsetForSticky = 0;

        if (show && ref.current) {
            topOffsetForSticky = ref.current.offsetHeight;
        }

        dispatch(setTopOffset(topOffsetForSticky));

        return () => {
            dispatch(setTopOffset(0));
        };
    }, [ref, show, dispatch]);

    const showUserProfileMenu = firstName || lastName || userProfileSections.length > 0;
    const logoUrl = `/${clientLogoURL}`.replace('//', '/') ?? '/Content/McatMenu/images/logo_menu.png';

    return <HorizontalBarContainer
        className='no-print'
        ref={ref}
        mainAreaTitleHeaderStyle={mainAreaTitleHeaderStyle}
        backgroundColor={horizontalBarBackGroundCalc}
        fontColor={textColor}>
        <MenuIcon
            onMenuIconClick={onMenuIconClick}
            textColor={textColor}
            menu={menu}
        />
        <HorizontalBarGlobalStyle />
        <LogoContainer width={clientLogoMaxWidth} height={clientLogoMaxHeight} isDummy={isDummy} isLoggedIn={isLoggedIn}>
            <a data-test-id='logo-href' href={clientLogoHref}>
                <img
                    style={{ width: customLogoGUID ? 'width:unset' : '' }}
                    src={`${customLogoGUID ? DownloadFileUrl(customLogoGUID) : logoUrl}`}
                    alt='logo_menu'
                />
            </a>
        </LogoContainer>

        <CaptionTextContainer customStyle={clientCaptionStyle}>{clientCaptionText}</CaptionTextContainer>

        <HorizontalBarRightItemsContainer horizontalBarIconColor={textColor}>
            <SmartSupportExtendedButton textColor={textColor} />
            {
                showChangeSupplierBU && <SupplierChangeBUPicker
                    menuSupplierBusinessUnits={menuSupplierBusinessUnits}
                />
            }
            { showUserProfileMenu && <UserProfileMenu
                    firstName={firstName}
                    lastName={lastName}
                    textColor={textColor}
                    elements={userProfileSections}
                    mainAreaSectionElementFontColor={mainAreaSectionElementFontColor}
                    horizontalBarBackGroundCalc={horizontalBarBackGroundCalc}
                    mainAreaBackgroundColor={mainAreaBackgroundColor}
                    isDummy={isDummy}
                    isLoggedIn={isLoggedIn}
                />
            }
            <SmartSupportButton textColor={textColor} />
            <HorizontalBarRightItemsLastContainer>
                <LanguagePicker isDummy={isDummy} isLoggedIn={isLoggedIn} currentLanguage={userLanguage} languages={languages || []} />
                {!isDummy && isLoggedIn
                    && <><LogoutButton data-test-id='logout-button' setLogoutDialogVisible={setLogoutDialogVisible} />
                        <LogoutDialog
                            userLanguage={userLanguage}
                            setLogoutDialogVisible={setLogoutDialogVisible}
                            isLogoutDialogVisible={isLogoutDialogVisible}
                        />
                    </>
                }
            </HorizontalBarRightItemsLastContainer>
        </HorizontalBarRightItemsContainer>
    </HorizontalBarContainer>;
};
