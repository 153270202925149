import { getBaseUrlMOTFrontendWithClient, getBaseUrlMCAT } from 'clientUrls';
import { deleteTaskUrl } from 'routes/tasks/urls';

export const auditDataUrl = (auditId, userLanguage, from, accessKey) => {
    let url = `/api/audit/init?auditId=${auditId}&langCode=${userLanguage}`;
    if (from) {
        url += `&from=${from}`;
    }

    if (accessKey) {
        url += `&accessKey=${accessKey}`;
    }

    return url;
};

export const answerUrl = () => '/api/audit/question/answer';

export const prefillUrl = () => '/api/audit/prefill';

export const markQuestionUrl = () => '/api/audit/question/mark';

export const submitUrl = () => '/api/audit/submit';

export const auditCoverPageUrl = (auditId, redirect) => {
    if (redirect === false) {
        return `${getBaseUrlMCAT()}/Audit/Result/Index/${auditId}?redirect=false`;
    }

    return `${getBaseUrlMCAT()}/Audit/Result/Index/${auditId}`;
};

export const auditAddUrl = () => `${getBaseUrlMCAT()}/Audit/Add/Index`;

export const auditDeleteCommentUrl = () => '/api/audit/question/comment/delete';

export const auditAddCommentUrl = () => '/api/audit/question/comment/add';

export const auditsToDoUrl = (auditId) => `/api/audit/todos?auditId=${auditId}`;

export const auditsFilloutMOT = (auditId) => `${getBaseUrlMOTFrontendWithClient()}/audits/fillout/${auditId}`;

export const auditDeleteTaskUrl = () => deleteTaskUrl();

export const auditDelegateInternalUsersUrl = () => '/api/audit/delegate/internalusers';

export const auditDelegateUrl = () => '/api/audit/delegate';

export const innovationPageUrl = (innovationId) => `${getBaseUrlMCAT()}/Innovation/Innovation/Index/${innovationId}`;

export const tableQuestionUrl = (templateId, auditId, surveyToQuestionRelID, guid) => {
    const base = getBaseUrlMCAT();
    return `${base}/TableQuestion/Index?auditId=${auditId}&surveyToQuestionRelID=${surveyToQuestionRelID}&templateId=${templateId}&guid=${guid}`;
};

export const refreshTableQuestionUrl = (questionToSurveyId, auditId) => {
    const baseUrl = '/api/audit/table-question';
    return `${baseUrl}?auditId=${auditId}&questionToSurveyId=${questionToSurveyId}`;
};

export const changeSupervisor = () => '/api/audit/team/changeSupervisor';
export const getSupervisors = () => '/api/audit/team/getSupervisors';

export const addAuditor = () => '/api/audit/team/addAuditor';
export const getAuditors = () => '/api/audit/team/getAuditors';
export const deleteAuditor = () => '/api/audit/team/deleteAuditor';

export const addReviewer = () => '/api/audit/team/addReviewer';
export const getReviewers = () => '/api/audit/team/getReviewers';
export const deleteReviewer = () => '/api/audit/team/deleteReviewer';

export const addViewer = () => '/api/audit/team/addViewer';
export const getViewers = () => '/api/audit/team/getViewers';
export const deleteViewer = () => '/api/audit/team/deleteViewer';
