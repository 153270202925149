import { colorNames } from 'app/app.styles';
import styled from 'styled-components';

export const NotificationBarContainer = styled.div`
    z-index: 11000000;
    position: fixed;
    animation: enter-leave ${(props) => props.duration + 0.3}s ease-in-out;
    width: 100%;
    height: auto;
    @keyframes enter-leave {
        0% {
            top: ${(props) => props.topOffset - 40}px;
        }
        12% {
            top: ${(props) => props.topOffset}px;
        }
        75% {
            top: ${(props) => props.topOffset}px;
        }
        100% {
            top: ${(props) => props.topOffset - 40}px;
        }
    }
`;

export const NotificationBarWrapper = styled.div`
    background: var(${(props) => props.backgroundColor});
    color: var(${colorNames.ZenWhite});
    height: 100%;
`;

export const NotificationBarContent = styled.div`
    padding: 10px;
    font-weight: bold;
`;
