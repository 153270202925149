import { fonts } from 'app/app.fonts';
import { bootstrapBreakpoints } from 'app/App.utils';
import styled, { createGlobalStyle } from 'styled-components';

export const UserProfileMenuHeaderWrapper = styled.div`
    font-size: 13px;
    i {
        text-align: center;
        width: 20px;
    }
    ${(props) => (props.mainAreaSectionElementFontColor ? `color:${props.mainAreaSectionElementFontColor}` : '')}
`;

export const UserProfileMenuWrapper = styled.div`
    margin-left: 5px;
    margin-right: 5px;
    vertical-align: middle;
    line-height: 1;
    outline: 0;
    display: inline-block;
`;

export const UserProfileMenuFirstName = styled.div`
    margin-bottom: 2px;
    display: block;
    line-height: 1;
    text-align: right;
    font-size: 13px;
    font-family: ${fonts};
    outline: 0;
    color: ${(props) => props.textColor};
`;

export const UserProfileMenuLastName = styled.div`
    display: block;
    line-height: 1;
    text-align: right;
    font-size: 13px;
    font-family: ${fonts};
    outline: 0;
    color: ${(props) => props.textColor};
`;

export const UserProfileMenuGlobalStyle = createGlobalStyle`
    .user-profile-menu-window {
        position: fixed;
        background-color: ${(props) => props.mainAreaBackgroundColor};
        border: solid 1px ${(props) => props.horizontalBarBackGroundCalc};
        .k-window-titlebar {
            display: none;
        }
        top:${(props) => props.top}px !important;
        height: unset !important;
        box-shadow: none !important;
        left: unset !important;
        @media only screen and (max-width: ${bootstrapBreakpoints.md - 1}px) {
            width: 100% !important;
            right: 0px !important;
            ${UserProfileMenuHeaderWrapper} {
                font-size: 17px !important;
            }           
        }

        @media only screen and (min-width: ${bootstrapBreakpoints.md}px) {
            width: 25% !important;
            right: 0px !important;
            ${UserProfileMenuHeaderWrapper} {
                font-size: 17px !important;
            }
        }

        @media only screen and (min-width: ${bootstrapBreakpoints.lg}px) {
            width: 16.6666% !important;
            right: 50px !important;
            ${UserProfileMenuHeaderWrapper} {
                font-size: 13px !important;
            }
        }
    }
`;

export const UserProfileMenuLanguageWrapper = styled.div`
    margin-bottom: 12px;
`;

export const UserProfileMenuItemWrapper = styled.div`
    margin: 5px 0px 5px 0px;
    ${(props) => (props.clickable ? `
    a:hover,
    span:hover {
        text-decoration: underline;
    }
    a,
    span {
        cursor: pointer;` : '')}
    }
    ${(props) => (props.spacer ? 'margin-bottom: 10px;' : '')}
    a {
        text-decoration: none;
        ${(props) => (props.mainAreaSectionElementFontColor ? `color:${props.mainAreaSectionElementFontColor};` : '')}
    }
    ${(props) => (props.mainAreaSectionElementFontColor ? `color:${props.mainAreaSectionElementFontColor};` : '')}
`;

export const UserProfileMenuElementWrapper = styled.div`
`;
