import { CountryFlag, FlagSizes } from 'components/country-flag/country-flag.component';
import { CountryFlagContainer } from 'components/country-flag/country-flag.styles';
import { cloneElement } from 'react';
import { SeparatorBlockWrapper, SeparatorContainer, SeparatorWrapper } from '../../menu.styles';

const getSeparatorTemplate = (data) => {
    const template = <SeparatorWrapper>
        <SeparatorBlockWrapper>{data.text}</SeparatorBlockWrapper>
        <SeparatorContainer><hr/></SeparatorContainer>
    </SeparatorWrapper>;
    return template;
};

const itemWithFlagAndEmailClientTemplate = (data) => {
    let template = data.text;
    if (data.isSeparator) {
        return getSeparatorTemplate(data);
    }
    let customIcon = '';
    if (typeof (data.additionalData) !== 'undefined' && data.additionalData.customIcon) {
        customIcon = <span className={`user-icon-search fa ${data.additionalData.customIcon} mt-1 me-1`}></span>;
    }

    let flagSnippet = '';
    if (typeof (data.additionalData) !== 'undefined' && data.additionalData.flagCode) {
        flagSnippet = <CountryFlagContainer align='flex-start' className="me-1 mt-1">
                <CountryFlag size={FlagSizes.SMALL} code={data.additionalData.flagCode}/>
            </CountryFlagContainer>;
    }

    if (typeof (data.additionalData) !== 'undefined') {
        const { text } = data;
        const user = <div className="text-break">{text} ({data.additionalData.email})</div>;
        template = <div className="d-flex align-items-stretch">
            {flagSnippet} {customIcon}{user}
        </div>;
    }

    return template;
};

const itemWithFlagClientTemplate = (data) => {
    let template = data.text;

    if (data.isSeparator) {
        return getSeparatorTemplate(data);
    }

    if (typeof (data.additionalData) !== 'undefined' && data.additionalData.flagCode) {
        template = <div className="d-flex align-items-stretch">
         {<CountryFlagContainer>
                <CountryFlag className="me-1" size={FlagSizes.SMALL} code={data.additionalData.flagCode}/> <span>{data.text}</span>
            </CountryFlagContainer>}
        </div>;
    }

    return template;
};

const itemWithFlagAndSupplierNameClientTemplate = (data) => {
    let template = data.text;

    if (data.isSeparator) {
        return getSeparatorTemplate(data);
    }

    if (typeof (data.additionalData) !== 'undefined' && data.additionalData.flagCode && data.additionalData.supplierName) {
        const flagSnippet = <CountryFlagContainer>
            <CountryFlag className="me-1" size={FlagSizes.SMALL} code={data.additionalData.flagCode}/>
        </CountryFlagContainer>;
        template = <div>
            {data.text} <div className="d-flex align-items-stretch"> {flagSnippet} <span>{data.additionalData.supplierName}</span></div>
        </div>;
    }

    return template;
};

const itemContractClientTemplate = (data) => {
    let template = data.text;

    if (data.isSeparator) {
        return getSeparatorTemplate(data);
    }
    if (typeof (data.additionalData) !== 'undefined') {
        const text = <div>{data.text}</div>;

        let categoryName = '';
        if (data.additionalData.categoryName) {
            categoryName += <div>{data.additionalData.categoryName}</div>;
        }

        let { startDate } = data.additionalData;
        if (startDate === '') {
            startDate = <i className="fa fa-calendar-times-o"></i>;
        }

        let { endDate } = data.additionalData;
        if (endDate === '') {
            endDate = <i className="fa fa-calendar-times-o"></i>;
        }
        const status = <div>{data.additionalData.statusName}
            <span className="ml-1"> {startDate} <i className="fa fa-arrow-right mx-1"></i> {endDate}</span>
        </div>;

        let buIndex = '';
        if (data.additionalData.buIndex) {
            const flagSnippet = <CountryFlagContainer className='me-1'>
                <CountryFlag size={FlagSizes.SMALL} code={data.additionalData.flagCode}/>
            </CountryFlagContainer>;
            buIndex = <div className='d-flex'>
                <div className="align-self-center">{flagSnippet}</div><div>{data.additionalData.buIndex}</div>
            </div>;
        }

        let mainParty = '';
        if (data.additionalData.mainPartyType && data.additionalData.mainPartyIndex) {
            const mainPartyFlagSnippet = <CountryFlagContainer className='mx-1'>
                <CountryFlag size={FlagSizes.SMALL} code={data.additionalData.mainPartyFlagCode}/>
            </CountryFlagContainer>;

            mainParty = <div className='d-flex'>
                {data.additionalData.mainPartyType} {mainPartyFlagSnippet}{data.additionalData.mainPartyIndex} {data.additionalData.mainPartyName}
            </div>;
        }

        template = <div>{text} {categoryName} {status} {buIndex} {mainParty}</div>;
    }

    return template;
};

const getDefaultSearchClientTemplate = (data) => {
    if (data.isSeparator) {
        return getSeparatorTemplate(data);
    }

    return data.text;
};

const matchClientTemplate = (data, code) => {
    if (code === 'Projects_Search' || code === 'Supplier_Search' || code === 'Category_Search' || code === 'Customers_Search') {
        return itemWithFlagClientTemplate(data);
    }
    if (code === 'Settings_UsersSearch') {
        return itemWithFlagAndEmailClientTemplate(data);
    }
    if (code === 'PPAP_Search' || code === 'Rollout_Search') {
        return itemWithFlagAndSupplierNameClientTemplate(data);
    }
    if (code === 'Contracts_Search') {
        return itemContractClientTemplate(data);
    }

    return getDefaultSearchClientTemplate(data);
};

export const itemRender = (li, itemProps, code) => {
    const itemChildren = matchClientTemplate(itemProps.dataItem, code);
    return cloneElement(li, li.props, itemChildren);
};
