import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showInternalErrorSelector } from 'store/internal-error-500-action/internal-error-500-action.selectors';
import { showNotFoundSelector } from 'store/not-found-404-action/not-found-404-action.selectors';
import { currentUserLoggedInSelector } from 'store/user/user.selector';
import { setSignIn } from 'store/app/appSetter.slice';

export const ProtectedRoute = (props) => {
    const isInternalError = useSelector(showInternalErrorSelector);
    const isNotFound = useSelector(showNotFoundSelector);
    const isErrorPage = isNotFound || isInternalError;
    const dispatch = useDispatch();
    const isAuthenticated = useSelector(currentUserLoggedInSelector) === true;
    const hasAccess = isAuthenticated;
    useEffect(() => {
        if (!hasAccess && !isErrorPage) {
            const redirectUrl = window.location.href;
            dispatch(setSignIn({ value: true, redirectUrl }));
        }
    }, [dispatch, hasAccess, isErrorPage]);

    if (hasAccess && !isErrorPage) {
        return props.children;
    }
    return null;
};
