import styled from 'styled-components';

export const IconWrapper = styled.span`
    ${(props) => (props.color ? `color: ${props.color};` : '')}
    ${(props) => (props.fontSize ? `font-size: ${props.fontSize};` : '')}
    ${(props) => (props.xs ? `@media (min-width: 0px) { font-size: ${props.xs.fontSize}px; }` : '')}
    ${(props) => (props.sm ? `@media (min-width: 576px) { font-size: ${props.sm.fontSize}px; }` : '')}
    ${(props) => (props.md ? `@media (min-width: 768px) { font-size: ${props.md.fontSize}px; }` : '')}
    ${(props) => (props.lg ? `@media (min-width: 992px) { font-size: ${props.lg.fontSize}px; }` : '')}
    ${(props) => (props.xl ? `@media (min-width: 1200px) { font-size: ${props.xl.fontSize}px; }` : '')}
    ${(props) => (props.xxl ? `@media (min-width: 1400px) { font-size: ${props.xxl.fontSize}px; }` : '')}
`;
