import { NotFoundLazyWrapper } from 'app/routes';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showNotFoundSelector } from 'store/not-found-404-action/not-found-404-action.selectors';
import { toggleSpinner } from 'store/spinner/spinner.slice';

export const NotFound404Action = () => {
    const show = useSelector(showNotFoundSelector);
    const dispatch = useDispatch();

    useEffect(() => {
        if (show) {
            toggleSpinner(false, dispatch);
        }
    }, [show, dispatch]);

    return (show ? <NotFoundLazyWrapper/> : null);
};
