import { keyAssessmentsUrl } from 'components/app/navigationBar/urls';
import { useEffect, useState } from 'react';
import MOTApi from 'utils/motApi';
import { Row, Col } from 'react-bootstrap';
import { menuIdsSelector } from 'store/navigation-bar/navigation-bar.selectors';
import { useSelector } from 'react-redux';
import {
    KeyAssessmentNameWrapper,
    KeyAssessmentValueWrapper,
    KeyAssessmentWrapper,
    SectionHeader,
    SectionWrapper,
} from '../../menu.styles';

export const KeyAssessmentSection = ({ section, color, code }) => {
    const [data, setData] = useState(null);
    const id = useSelector(menuIdsSelector(code));

    useEffect(() => {
        const refreshKeyAssessments = (supplierId) => {
           MOTApi.post(keyAssessmentsUrl(), { supplierId })
           .then((resp) => setData(resp.data));
        };

        refreshKeyAssessments(id);
    }, [id]);

    return (
    id && <SectionWrapper>
        <SectionHeader color={color}>
            {section.name}
        </SectionHeader>
            {
                data && <KeyAssessmentWrapper>{data.map((assessmentData, index) => <Row key={index}>
                         <KeyAssessmentNameWrapper className='px-0' sm={6}>{assessmentData.surveyName}</KeyAssessmentNameWrapper>
                            <Col className='px-0 text-center' sm={6}>
                                <KeyAssessmentValueWrapper
                                    color={assessmentData.fontColor}
                                    background={assessmentData.backgroundColor}
                                >
                                    {assessmentData.result}
                                </KeyAssessmentValueWrapper>
                            </Col>
                    </Row>)}
                </KeyAssessmentWrapper>
            }
    </SectionWrapper>
);
};
