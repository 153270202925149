import { createSlice } from '@reduxjs/toolkit';

export const pageHeaderSlice = createSlice({
  name: 'pageHeader',
  initialState: {
    text: '',
  },
  reducers: {
    setPageHeader: (state, action) => {
        state.text = action.payload;
    },
  },
});

export const { setPageHeader } = pageHeaderSlice.actions;
export default pageHeaderSlice.reducer;
