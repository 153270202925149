/* eslint-disable no-unused-vars */
import styled, { createGlobalStyle } from 'styled-components';
import { fonts } from './app.fonts';
import colors from './styles/colors.scss';
import './styles/kendo.styles.scss';

export const footerHeight = 37;

export const colorNames = {
    ZenCyan: colors.ZenCyan,
    ZenFontCyan: colors.ZenFontCyan,
    ZenYellowLight: colors.ZenYellowLight,
    ZenFontYellowLight: colors.ZenFontYellowLight,
    ZenGreenLight: colors.ZenGreenLight,
    ZenFontGreenLight: colors.ZenFontGreenLight,
    ZenDarkRed: colors.ZenDarkRed,
    ZenFontDarkRed: colors.ZenFontDarkRed,
    ZenDarkGrey: colors.ZenDarkGrey,
    ZenFontDarkGrey: colors.ZenFontDarkGrey,
    ZenMenuDarkGreyBg: colors.ZenMenuDarkGreyBg,
    ZenFontMenuDarkGreyBg: colors.ZenFontMenuDarkGreyBg,
    ZenDarkCyan: colors.ZenDarkCyan,
    ZenRowBacklight: colors.ZenRowBacklight,
    ZenTopInfoBar: colors.ZenTopInfoBar,
    ZenFontTopInfoBar: colors.ZenFontTopInfoBar,
    ZenHeaderFontTopInfoBar: colors.ZenHeaderFontTopInfoBar,
    ZenWhite: colors.ZenWhite,
    ZenValidation: colors.ZenValidation,
    ZenGrey: colors.ZenGrey,
    ZenFontMenuLightGreyBg: colors.ZenFontMenuLightGreyBg,
    ZenMenuLightGreyBg: colors.ZenMenuLightGreyBg,
    ZenButtonsBar: colors.ZenButtonsBar,
    ZenTextGreen: colors.ZenTextGreen,
    ZenLightGrey: colors.ZenLightGrey,
    ZenBlack: colors.ZenBlack,
    ZenRedLight: colors.ZenRedLight,
    ZenFontGrey: colors.ZenFontGrey,
    SectionsColourBodyBorder: colors.SectionsColourBodyBorder,
    SectionsColourBodyFill: colors.SectionsColourBodyFill,
    SectionsColourBodyFillFont: colors.SectionsColourBodyFillFont,
    SectionsColourButtonFill: colors.SectionsColourButtonFill,
    SectionsColourButtonFillFont: colors.SectionsColourButtonFillFont,
    SectionsColourHeaderBorder: colors.SectionsColourHeaderBorder,
    SectionsColourHeaderFill: colors.SectionsColourHeaderFill,
    SectionsColourHeaderFont: colors.SectionsColourHeaderFont,
};

export const colorsStatic = {
  red: '#C00000',
  redLight: '#ff0000',
  green: '#00B050',
  warning: '#e46c0a',
  gray: '#787878',
  blue: '#00B0F0',
  yellow: '#ffc000',
};

export const initStyles = (styles) => {
    const {
        zenCyan, zenFontCyan,
        zenYellowLight, zenFontYellowLight,
        zenGreenLight, zenFontGreenLight,
        zenDarkRed, zenFontDarkRed,
        zenDarkGrey, zenFontDarkGrey,
        zenMenuDarkGreyBg, zenFontMenuDarkGreyBg,
        zenDarkCyan, zenRowBacklight,
        zenTopInfoBar, zenFontTopInfoBar,
        zenWhite, zenValidation,
        zenGrey, zenTextGreen,
        zenMenuLightGreyBg, zenFontMenuLightGreyBg,
        zenButtonsBar, zenLightGrey,
        zenBlack, zenFontGrey,
        zenRedLight,
        sectionsColourHeaderBorder,
        sectionsColourHeaderFont,
        sectionsColourHeaderFill,
        sectionsColourButtonFill,
        sectionsColourButtonFillFont,
        sectionsColourBodyFill,
        sectionsColourBodyFillFont,
        sectionsColourBodyBorder,
        zenHeaderFontTopInfoBar,
     } = styles.colors;

    const style = document.createElement('style');

    const stylesColors = `
    :root {
      ${colorNames.ZenCyan}:${zenCyan};
      ${colorNames.ZenFontCyan}:${zenFontCyan};
      ${colorNames.ZenYellowLight}:${zenYellowLight};
      ${colorNames.ZenFontYellowLight}:${zenFontYellowLight};
      ${colorNames.ZenGreenLight}:${zenGreenLight};
      ${colorNames.ZenFontGreenLight}:${zenFontGreenLight};
      ${colorNames.ZenDarkRed}:${zenDarkRed};
      ${colorNames.ZenFontDarkRed}:${zenFontDarkRed};
      ${colorNames.ZenDarkGrey}:${zenDarkGrey};
      ${colorNames.ZenFontDarkGrey}:${zenFontDarkGrey};
      ${colorNames.ZenMenuDarkGreyBg}:${zenMenuDarkGreyBg};
      ${colorNames.ZenFontMenuDarkGreyBg}:${zenFontMenuDarkGreyBg};
      ${colorNames.ZenDarkCyan}:${zenDarkCyan};
      ${colorNames.ZenRowBacklight}:${zenRowBacklight};
      ${colorNames.ZenTopInfoBar}:${zenTopInfoBar};
      ${colorNames.ZenFontTopInfoBar}:${zenFontTopInfoBar};
      ${colorNames.ZenHeaderFontTopInfoBar}:${zenHeaderFontTopInfoBar};
      ${colorNames.ZenWhite}:${zenWhite};
      ${colorNames.ZenValidation}:${zenValidation};
      ${colorNames.ZenGrey}:${zenGrey};
      ${colorNames.ZenMenuLightGreyBg}:${zenMenuLightGreyBg};
      ${colorNames.ZenFontMenuLightGreyBg}:${zenFontMenuLightGreyBg};
      ${colorNames.ZenButtonsBar}:${zenButtonsBar};
      ${colorNames.ZenTextGreen}:${zenTextGreen};
      ${colorNames.ZenLightGrey}:${zenLightGrey};
      ${colorNames.ZenBlack}:${zenBlack};
      ${colorNames.ZenRedLight}:${zenRedLight};
      ${colorNames.ZenFontGrey}:${zenFontGrey};
      ${colorNames.SectionsColourBodyBorder}:${sectionsColourBodyBorder};
      ${colorNames.SectionsColourBodyFill}:${sectionsColourBodyFill};
      ${colorNames.SectionsColourBodyFillFont}:${sectionsColourBodyFillFont};
      ${colorNames.SectionsColourButtonFill}:${sectionsColourButtonFill};
      ${colorNames.SectionsColourButtonFillFont}:${sectionsColourButtonFillFont};
      ${colorNames.SectionsColourHeaderBorder}:${sectionsColourHeaderBorder};
      ${colorNames.SectionsColourHeaderFont}:${sectionsColourHeaderFont};
      ${colorNames.SectionsColourHeaderFill}:${sectionsColourHeaderFill};
    }`;

    const colorStyleSheet = document.createTextNode(stylesColors);
    style.appendChild(colorStyleSheet);
    document.documentElement.append(style);
};

export const initDefaults = () => {
  const style = document.createElement('style');
  const stylesColors = `
    :root {
      ${colorNames.ZenCyan}: ${window.env.ZenCyan};
      ${colorNames.ZenFontCyan}: #ffffff;
    }`;

    const colorStyleSheet = document.createTextNode(stylesColors);
    style.appendChild(colorStyleSheet);
    document.documentElement.append(style);
};

export const GlobalStyle = createGlobalStyle`
  body {
    font-family: ${fonts};
    font-size: .75em;
  }

  .clickable {
    cursor: pointer;
  }

  .pointer {
    cursor: pointer;
  }

  .text-hint {
    color: var(${colorNames.ZenDarkGrey}) !important;
  }
  
  .text-cyan {
    color: var(${colorNames.ZenCyan}) !important;
  }

  .text-disabled {
    pointer-events: none;
    opacity: 0.7;
    cursor: default;
  }

  .em-highlighter {
      color: black;
      font-weight: bold;
      font-style: initial;
      background-color: yellow;
  }
  @media print {
    .no-print, .no-print *
    {
        display: none !important;
    }
  }
`;

export const MOTContainerWrapper = styled.div`
  height: 100%;
  padding-left: 19px;
  padding-right: 19px;
  margin-top: 8px;
`;

export const MOTContainer = styled.div`
  ${(props) => (props.disabled ? 'pointer-events:none;' : '')}
  ${(props) => (props.backgroundColor ? `background-color: ${props.backgroundColor};` : '')}
  height: 100%; 

  a:link {
    color: var(${colorNames.ZenDarkCyan});
    text-decoration: none;
  }
  a:visited {
      color: var(${colorNames.ZenDarkCyan});
  }
  a:hover {
      color: var(${colorNames.ZenDarkCyan});
      text-decoration: underline;
  }
`;

export const AppContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  min-height: calc(100vh - ${footerHeight}px);
  @media print {
    margin-bottom: 15px;
  }
`;
