import { ProtectedRoute } from 'components/auth/protected-route.component';
import { lazy } from 'react';
import { LazyWrapper } from '../routes';

const NCRInfoPageLazy = lazy(() => import('routes/ncr/ncReport/ncReport.component'));

export const ncrRoutes = [
    // ncrs
    { path: ':clientPrefix/ncr/ncReport/:ncrId', component: <LazyWrapper><ProtectedRoute><NCRInfoPageLazy/></ProtectedRoute></LazyWrapper> },
];
