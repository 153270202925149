import { useSelector } from 'react-redux';
import { supportWidgetSelector } from 'store/controls/controls.selectors';
import {
    SmartSupportExtendedButtonContainer,
    SmartSupportExtendedButtonText,
} from './smartsupp-extended-button.styles';

export const SmartSupportExtendedButton = (textColor) => {
    const data = useSelector(supportWidgetSelector);
    const {
        position,
        primaryColor,
        customButtonStyle,
        isEnabled,
    } = data || {};
    const shouldBeShow = position === 2;

    return shouldBeShow && isEnabled && <SmartSupportExtendedButtonContainer
        textColor={textColor}
        customCss={customButtonStyle}
        backgroundColor={primaryColor}
        // eslint-disable-next-line no-undef
        onClick={() => smartsupp('chat:open')}>
        <i className="fa fa-comments" aria-hidden="true"></i>
        <SmartSupportExtendedButtonText textColor={textColor}>live chat</SmartSupportExtendedButtonText>
    </SmartSupportExtendedButtonContainer>;
};
