import { Dialog as KendoDialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { MOTContainer } from 'app/app.styles';
import { useWindowResize } from 'hooks/useWindowResize';
import React, { useEffect, useState, useRef } from 'react';
import { logError } from 'utils/logger';
import {
    DialogButtonsWrapper,
    DialogButtonWrapper,
    DialogContainer,
    DialogGlobalStyles,
    DialogWrapper,
} from './dialog.styles';

const minWidth = 500;

export const Dialog = React.forwardRef((props, ref) => {
    let dWidth = props.width;
    if (!dWidth || (dWidth < minWidth && (typeof dWidth !== 'number' && !dWidth.includes('%')))) {
        dWidth = minWidth;
    }

    const [innerActive, setInnerActive] = useState(true);

    const [dialogWidth, setNewWidth] = useState(dWidth);
    const { width } = useWindowResize();
    const dialogRef = useRef(null);
    const onRefChange = (node) => {
        const localRef = ref || dialogRef;
        if (typeof localRef === 'function') {
            localRef(node);
        } else {
            // eslint-disable-next-line no-param-reassign
            localRef.current = node;
        }
        if (node) {
            dialogRef.current = node;
        }
    };
    useEffect(() => {
        const newWidth = dWidth > width ? width - 20 : dWidth;
        if (newWidth !== dialogWidth) {
            setNewWidth(newWidth);
        }
    }, [dialogWidth, dWidth, width]);

    useEffect(() => {
        if (dialogRef && dialogRef.current) {
            dialogRef.current.element.focus();
        }
    }, []);

    if (!props.onClose) {
        logError('Dialog requires onClose method');
        throw new Error('Dialog requires onClose method');
    }

    return <DialogWrapper>
                <DialogGlobalStyles/>
                <KendoDialog
                    closeIcon={false}
                    {...props}
                    ref={onRefChange}
                    onClose={() => {
                        setTimeout(() => {
                            const isAnyDropdownOpened = document.querySelectorAll('div.k-popup.k-list-container .k-list').length > 0;
                            if (!isAnyDropdownOpened) {
                                const allDialogs = document.querySelectorAll('div.k-dialog-wrapper');
                                props.onClose();
                                if (allDialogs.length > 1) {
                                    const nextDialog = allDialogs[allDialogs.length - 2];
                                    nextDialog.focus();
                                }
                            }
                        }, 0);
                    }}
                    width={dialogWidth}>
                    <DialogContainer data-test-id={`${props['data-test-id']}-dialog`}>
                        <MOTContainer>
                            {props.children}
                        </MOTContainer>
                    </DialogContainer>
                    <DialogButtonsWrapper>
                        <DialogActionsBar>
                            {
                                props.buttons.map((button, index) => <DialogButtonWrapper key={button.buttonId || index}
                                    enabled={(button.enabled === undefined ? innerActive && !props.activeButtons : false)
                                    || (props.activeButtons && props.activeButtons[button.buttonId])
                                    || button.enabled
                                    }
                                    data-test-id={`${props['data-test-id']}-dialog-button-${button.buttonId || index}`}
                                    primary={button.primary}
                                    onClick={() => {
                                        setInnerActive(false);
                                        button.onClick();
                                    }}>
                                    <div>{button.text}</div>
                                </DialogButtonWrapper>)
                            }
                        </DialogActionsBar>
                    </DialogButtonsWrapper>
                </KendoDialog>
            </DialogWrapper>;
});
