import { DropDownList } from 'components/kendo/drop-down-list/drop-down-list.component';
import styled from 'styled-components';

export const SupplierChangeBUPickerWrapper = styled.div`
    margin-right: 5px;
    margin-left: 5px;
    line-height: 24px;
    display: inline-block;
    text-align: right;

    ${DropDownList} {
        .k-dropdownlist button {
            display: block;
        }

        .k-picker {
            background-color: transparent;
            border-color: #dadada !important;
            color: #ffffff !important;
            width: 200px;

            :hover {
                background-color: transparent;
            }
        }

        .k-input {
            color: #FFF !important;
        }
    }
`;
