import { YesNoDialog } from 'components/dialog/yes-no/yes-no-dialog.component';
import { useDispatch, useSelector } from 'react-redux';
import { commonTextLogoutQuestionSelector, commonTextLogoutTitleSelector } from 'store/translations/translations.selectors';
import { setSignOut } from 'store/user/user.slice';

export const LogoutDialog = ({ isLogoutDialogVisible, setLogoutDialogVisible, userLanguage }) => {
    const dispatch = useDispatch();
    const title = useSelector(commonTextLogoutTitleSelector(userLanguage));
    const text = useSelector(commonTextLogoutQuestionSelector(userLanguage));

    return <YesNoDialog
        title={title}
        text={text}
        yesAction={
            () => {
                dispatch(setSignOut(true));
            }
        }
        noAction={() => setLogoutDialogVisible(false)}
        isVisible={isLogoutDialogVisible}
        width={300}
    />;
};
