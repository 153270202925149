/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import { auditsFilloutMOT } from 'routes/audits/urls';
import { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { supportWidgetSelector, appSupportWidgetMobileSelector } from 'store/controls/controls.selectors';
import { setSupportWidgetMobile } from 'store/controls/controls.slice';
import MOTApi from 'utils/motApi';
import { onMessageSentUrl } from './urls';

export const SupportWidget = () => {
    const data = useSelector(supportWidgetSelector);
    const {
        licenceKey,
        userName,
        userEmail,
        userId,
        language,
        cookieDomain,
        primaryColor,
        isHidden,
        orientation,
        baseUrl,
        rating,
        group,
        position,
        anonymizedUserName,
        anonymizedUserEmail,
    } = data || {};
    const containerRef = useRef();
    const scriptRef = useRef();
    const userProfileUrl = `${baseUrl}/Administration/AdminUsers/Edit/${userId}`;
    const requestUrl = window.location.href;
    const showOnMobile = useSelector(appSupportWidgetMobileSelector);

    useEffect(() => {
        if (licenceKey) {
            window._smartsupp = createSmartSuppWidget(licenceKey, requestUrl, userName, userEmail, userId, userProfileUrl,
                language, cookieDomain, primaryColor, isHidden, containerRef, scriptRef, showOnMobile, orientation, rating,
                group, anonymizedUserName, anonymizedUserEmail);
        }
        const container = containerRef.current;
        const script = scriptRef.current;
        return () => {
            document._smartsupp = undefined;
            try {
                container.removeChild(script);
            } catch {
                //
            }
        };
    }, [cookieDomain, isHidden, language, licenceKey, primaryColor, requestUrl, orientation,
        userEmail, userId, userName, userProfileUrl, showOnMobile, rating, group, anonymizedUserName, anonymizedUserEmail]);
    const shouldBeHidden = position === 2 || position === 3;

    return (<>
        {shouldBeHidden
            && <style dangerouslySetInnerHTML={{
                __html: ` @media (min-width: 769px) {
                #smartsupp-widget-container div[data-testid="widgetButtonFrame"] {
                display: none; } }
                `,
            }} />
        }
    </>);
};

const createSmartSuppWidget = (licenceKey,
    requestUrl,
    userName,
    userEmail,
    userId,
    userProfileUrl,
    language,
    cookieDomain,
    primaryColor,
    isHidden,
    containerRef,
    scriptRef,
    showOnMobile,
    orientation,
    rating,
    group,
    anonymizedUserName,
    anonymizedUserEmail) => {
    const _smartsupp = window._smartsupp || {};
    _smartsupp.key = licenceKey;
    _smartsupp.offsetX = 32; // offset from left or right, default value : 10
    _smartsupp.offsetY = 42; // offset from top by, default value :  100
    _smartsupp.cookieDomain = cookieDomain;
    _smartsupp.hideWidget = isHidden;
    _smartsupp.sendEmailTanscript = false;
    _smartsupp.ratingEnabled = rating;
    _smartsupp.ratingComment = false;
    _smartsupp.requireLogin = false;
    _smartsupp.loginEmailControl = false;
    _smartsupp.offlineNameControl = false;
    _smartsupp.widget = 'button';
    _smartsupp.color = primaryColor;
    _smartsupp.hideMobileWidget = !showOnMobile;
    _smartsupp.orientation = orientation;

    _smartsupp.offlineControls = [{
        xtype: 'textinput',
        name: 'name',
        label: 'Name',
        value: userName,
    }];

    // eslint-disable-next-line func-names
    window.smartsupp || (function (d) {
        const s = d.getElementsByTagName('script')[0];
        const c = d.createElement('script');
        c.setAttribute('support-widget', '');
        // eslint-disable-next-line func-names
        const o = function () {
            // eslint-disable-next-line prefer-rest-params
            o._.push(arguments);
        };
        o._ = [];
        c.type = 'text/javascript'; c.charset = 'utf-8'; c.async = true;
        c.src = `//www.smartsuppchat.com/loader.js?t=${new Date().getTime()}`;
        s.parentNode.insertBefore(c, s);
        containerRef.current = s.parentNode;
        scriptRef.current = c;
        window.smartsupp = o;
    }(document));
    window.smartsupp('recording:disable', true);
    const _availableLanguages = ['en', 'ar', 'br', 'cn', 'cs', 'da', 'de', 'es', 'fa', 'fi', 'fr',
        'he', 'hr', 'hu', 'it', 'ka', 'lt', 'nl', 'no', 'pl', 'pt', 'ro', 'ru', 'sk', 'sl', 'sv', 'tr', 'tw', 'uk'];

    if (language && language.length > 2) {
        if (language === 'zh-cn') { // special case for china
            language = language.substring(3, 5);
        } else {
            language = language.substring(0, 2);
        }

        const indexOfLang = _availableLanguages.indexOf(language);
        if (indexOfLang >= 0) {
            window.smartsupp('language', _availableLanguages[indexOfLang]);
        } else {
            window.smartsupp('language', 'en');
        }
    } else {
        window.smartsupp('language', 'en');
    }

    // eslint-disable-next-line no-unused-vars
    window.smartsupp('on', 'message_sent', (message) => {
        if (anonymizedUserEmail !== anonymizedUserName) {
            if (userEmail !== '') {
                window.smartsupp('email', userEmail);
            }

            if (userName !== '') {
                window.smartsupp('name', userName);
            }
        }

        // eslint-disable-next-line no-unused-vars
        MOTApi.post(onMessageSentUrl(), {}, (resp) => {
        });
    });

    if (group) {
        window.smartsupp('group', group);
    }

    if (userEmail !== '') {
        window.smartsupp('email', anonymizedUserEmail);
    }

    if (userName !== '') {
        window.smartsupp('name', anonymizedUserName);
    }

    if (userId !== '') {
        window.smartsupp('variables', {
            userId: {
                label: 'User ID ',
                value: userId,
            },
            userProfile: {
                label: 'User Profile',
                value: userProfileUrl,
            },
            RequestUrl: {
                label: 'Request Url',
                value: requestUrl,
            },
        });
    } else {
        window.smartsupp('variables', {
            RequestUrl: {
                label: 'Request Url',
                value: requestUrl,
            },
        });
    }

    return _smartsupp;
};

export const useShowSupportWidgetOnMobile = () => {
    const dispatch = useDispatch();
    const previousSetting = useSelector(appSupportWidgetMobileSelector);
    const showOnMobileDeviceUrls = [
        auditsFilloutMOT(''),
    ];

    const setSupportWidgetMobileF = () => {
        const url = window.location.href;
        const shouldSet = showOnMobileDeviceUrls.filter((x) => url.includes(x)).length > 0;
        if (shouldSet && previousSetting !== true) {
            dispatch(setSupportWidgetMobile(true));
        } else if (!shouldSet && previousSetting === true) {
            dispatch(setSupportWidgetMobile(false));
        }
    };

    return [setSupportWidgetMobileF];
};
