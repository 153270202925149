/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isClientPrefixValid } from 'app/clientPrefix';
import { initApp } from 'store/app/app.slice';
import { useTranslations } from 'hooks/useTranslation';
import { setCommonTranslations } from 'store/translations/translations.slice';
import { NotFound404Action } from 'components/not-found/not-found-404-action';
import { InternalError500Action } from 'components/internal-error/internal-error-500-action';
import { toggleNotFound } from 'store/not-found-404-action/not-found-404-action.slice';
import { showInternalErrorSelector } from 'store/internal-error-500-action/internal-error-500-action.selectors';
import { showNotFoundSelector } from 'store/not-found-404-action/not-found-404-action.selectors';
import { NavigationBar } from 'components/app/navigationBar/navigation-bar.component';
import { RouteWrapper } from 'components/app/route-wrapper.component';
import { PageHeader } from 'components/page-header/page-header.component';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { currentUserNumberDateFormatSelector } from 'store/user/user.selector';
import { toggleNavbar } from 'store/navigation-bar/navigation-bar.slice';
import store from 'store/store';
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query';
import { Footer } from 'components/footer/footer.component';
import { initKendo, toUpperLanguageAfterDash } from './App.utils';
import { routesWithLayout } from './routesWithLayout';
import { routes } from './routes';
import { appSnippetsUrl } from './urls';
import { appInitializedSelector } from '../store/app/app.selectors';
import { AppContainer, initDefaults } from './app.styles';
import { SupportWidget } from './support-widget/support-widget.component';

const queryClient = new QueryClient();
const baseRouteKey = 'route-';
const baseRouteLayoutKey = 'route-layout-';

initDefaults();

function App() {
  let cultureFormat = useSelector(currentUserNumberDateFormatSelector);
  const appInitialized = useSelector(appInitializedSelector);
  const [isKendoInit, setIsKendoInit] = useState(false);
  const isInternalError = useSelector(showInternalErrorSelector);
  const isNotFound = useSelector(showNotFoundSelector);
  const isErrorPage = isNotFound || isInternalError;

  const { translations, setTranslations, userLanguage } = useTranslations(appSnippetsUrl, true);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initApp({ setTranslations }));
  }, [dispatch, setTranslations]);

  useEffect(() => {
    const initKendoWrapper = async () => {
      const loadedData = initKendo(cultureFormat, toUpperLanguageAfterDash(userLanguage), dispatch).then(() => {
        setIsKendoInit(true);
      });
      cultureFormat = loadedData.cultureFormatLoaded;
    };

    if (appInitialized && userLanguage && cultureFormat) {
      initKendoWrapper();
    }
  }, [cultureFormat, appInitialized, userLanguage]);

  useEffect(() => {
      if (Object.keys(translations).length) {
        dispatch(setCommonTranslations({ translations, userLanguage }));
      }
  }, [dispatch, translations, userLanguage]);

  useEffect(() => {
    if (appInitialized && !isClientPrefixValid(store.getState()) && !isInternalError) {
      dispatch(toggleNavbar(false));
      dispatch(toggleNotFound(true));
    }
  }, [dispatch, appInitialized, isInternalError]);

  return (
    !appInitialized || !isKendoInit
    ? null
    : <LocalizationProvider language={toUpperLanguageAfterDash(userLanguage)}>
      <IntlProvider locale={cultureFormat}>
        <QueryClientProvider client={queryClient}>
        {!isErrorPage && <>
        <AppContainer>
            <NavigationBar userLanguage={userLanguage}/>
            <PageHeader/>
            <Routes>
              <>
                {routesWithLayout.map((route, index) => <Route
                      key={`${baseRouteLayoutKey}${index}`}
                      path={route.path}
                      element={<RouteWrapper userLanguage={userLanguage} useLayout={true}>{route.component}</RouteWrapper>}
                  />)
                }

                {routes.map((route, index) => <Route
                      key={`${baseRouteKey}${index}`}
                      path={route.path}
                      element={<RouteWrapper useLayout={false}>{route.component}</RouteWrapper>}
                  />)
                }
              </>
            </Routes>
          </AppContainer>
          <Footer/>
          <SupportWidget/>
          </>
        }
        </QueryClientProvider>
        <NotFound404Action/>
        <InternalError500Action/>
      </IntlProvider>
    </LocalizationProvider>
  );
}

export default App;
