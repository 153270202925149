import { ModuleContentWrapper, ModuleWrapper } from '../menu.styles';
import { ModuleContent } from './module-content.component';
import { ModuleHeader } from './module-header.component';

export const Module = ({
    modules,
    closeMenu,
    useTrippleBlockVerticalBar,
    mainAreaBackgroundColor,
    mainAreaTitleColor,
    selectedModule,
    mainAreaSectionHeaderColor,
    editUserUrl,
    documentPreviewUrl,
}) => {
    let lg;
    let lgClass = '';

    if (useTrippleBlockVerticalBar) {
        lg = 'col-lg-10';
    } else {
        lgClass = 'col-lg-90-percent';
    }

    return (
        modules.map((module, index) => selectedModule === module.codeName && <ModuleWrapper
            key={index}
            mainareabackgroundcolor={mainAreaBackgroundColor}
            className={`${lgClass} p-0`}
            lg={lg}
            md={10}
            sm={10}
            xs={9}>
                <ModuleContentWrapper>
                    <ModuleHeader
                        mainAreaTitleColor={mainAreaTitleColor}
                        closeActionName={module.closeActionName}
                        moduleHeader={module.moduleHeader}
                        closeMenu={closeMenu}
                    />
                    <ModuleContent
                        mainAreaSectionHeaderColor={mainAreaSectionHeaderColor}
                        module={module}
                        editUserUrl={editUserUrl}
                        documentPreviewUrl={documentPreviewUrl}
                    />
                </ModuleContentWrapper>
            </ModuleWrapper>)
    );
};
