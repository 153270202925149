import { createSlice, current } from '@reduxjs/toolkit';

export const notificationSmallSlice = createSlice({
  name: 'notificationsBarSmall',
  initialState: {
    notifications: [],
  },
  reducers: {
    createSmallAlert: (state, action) => {
        const { message, type, guid } = action.payload;
        state.notifications.push({
            message,
            type,
            guid,
      });
    },
    deleteSmallAlert: (state, action) => {
      const guid = action.payload;
      state.notifications = current(state.notifications).filter((item) => item.guid !== guid);
    },
  },
});

export const { createSmallAlert, deleteSmallAlert } = notificationSmallSlice.actions;
export default notificationSmallSlice.reducer;
