import { logError } from 'utils/logger';

export const stringInject = (str, arr) => {
    if (typeof str !== 'string' || !(arr instanceof Array)) {
        return false;
    }

    return str.replace(/({\d})/g, (i) => arr[i.replace(/{/, '').replace(/}/, '')]);
};

export const parseHtmlEntities = (str) => str.replace(/&#([0-9]{1,3});/gi, (match, numStr) => {
        const num = parseInt(numStr, 10);
        return String.fromCharCode(num);
});

export const ensureUrlSlash = (text) => {
    if (!text || text.endsWith('/')) {
        return text;
    }

    const newText = `${text}/`;
    return newText;
};

export const getDescendantProp = (obj, desc) => {
    try {
        let data = obj;
        const arr = desc.split('.');
        while (arr.length && data && (data = data[arr.shift()]));
        return data;
    } catch (e) {
        logError(e);
        return null;
    }
};
