import { useSelector } from 'react-redux';
import { footerDataSelector } from 'store/footer/footer.selectors';
import {
    FooterCompanyWrapper,
    FooterCopyrightWrapper,
    FooterLicenseWrapper,
    FooterLinkWrapper,
    FooterWrapper,
    FooterWrapperInner,
} from './footer.styles';

export const Footer = () => {
    const footerData = useSelector(footerDataSelector);

    return footerData?.data && <FooterWrapper>
        <FooterWrapperInner>
            {footerData.data.licenceInfoConfig.clientInfo}<FooterCompanyWrapper>{footerData.data.licenceInfoConfig.companyName}</FooterCompanyWrapper>
            { footerData.data.licenceInfoConfig.licenseInfo
                && <FooterLicenseWrapper>{footerData.data.licenceInfoConfig.licenseInfo}</FooterLicenseWrapper>
            }
            <FooterCopyrightWrapper>Meercat ver. {footerData.data.version}
            {
                footerData.data.links.map((link, index) => <FooterLinkWrapper key={index}>
                    <a href={link.link}>{link.title}</a>
                </FooterLinkWrapper>)
            }
            </FooterCopyrightWrapper>
        </FooterWrapperInner>
    </FooterWrapper>;
};
