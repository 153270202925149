import { useSelector } from 'react-redux';
import { pageHeaderSelector } from 'store/page-header/page-header.selectors';
import { PageHeaderContainer, PageHeaderText } from './page-header.styles';

export const PageHeader = () => {
    const text = useSelector(pageHeaderSelector);
    return text && <PageHeaderContainer>
        <PageHeaderText>
            {text}
        </PageHeaderText>
    </PageHeaderContainer>;
};
