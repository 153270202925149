import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { menuUrl, selectModuleUrl } from 'components/app/navigationBar/urls';
import { toggleSpinner } from 'store/spinner/spinner.slice';
import { currentLanguageSelector } from 'store/translations/translations.selectors';
import { McatMenuSectionTypes } from 'utils/mcat-enums/system.enums';
import { menuLanguageSelector } from './navigation-bar.selectors';

let icons = {};

export const loadIcons = (loadedIcons) => {
    icons = loadedIcons;
};

export const getIcons = () => icons;

const prepareIcons = async (modules) => {
  const iconsLocal = {};
  await Promise.all(modules.map((m) => fetch(`${process.env.PUBLIC_URL}/${m.iconURL}`).then((resp) => resp.text()).then((resp) => {
    iconsLocal[m.codeName] = resp;
  })));
  return iconsLocal;
};

export const prepareMenu = async (menu) => {
  if (menu.modules.length > 0) {
    const iconsLocal = await prepareIcons(menu.modules);
    icons = iconsLocal;
  }
  menu.timestamp = new Date().getTime();

  return menu;
};

export const initMenu = createAsyncThunk('navigationBar/initMenu', async ({ MOTApi, langCode }, thunkApi) => {
  const { dispatch } = thunkApi;

  toggleSpinner(true, dispatch);
  const resp = await MOTApi.get(menuUrl(langCode));
  let menu = resp.data;
  menu = await prepareMenu(menu);
  toggleSpinner(false, dispatch);

  return menu;
},
{
  condition: (obj, { getState }) => {
    const state = getState();
    if (currentLanguageSelector(state) === menuLanguageSelector(state)) {
      return false;
    }
    return true;
  },
});

export const selectModule = createAsyncThunk('navigationBar/selectModule', async ({ MOTApi, module }) => {
  const dto = {
    module,
  };

  const resp = await MOTApi.post(selectModuleUrl(), dto);
  return resp ? module : '';
});

const getDefaultId = (column) => {
  const items = column.sections?.find((x) => x.type === McatMenuSectionTypes.Search)?.sectionItems;
  if (items && items.length) {
      return items[0].defaultValue;
  }
  return null;
};

const prepareIds = (menu) => {
  const ids = {};

  menu.modules.forEach((module) => {
    for (let i = 0; i < module.columns.length; i += 1) {
      const column = module.columns[i];
      const defaultValue = getDefaultId(column);
      if (defaultValue) {
        ids[module.codeName] = defaultValue;
        break;
      }
    }
  });

  return ids;
};

export const navigationBarSlice = createSlice({
  name: 'navigationBar',
  initialState: {
    show: false,
    menu: null,
    ids: {},
    topOffset: 0,
  },
  reducers: {
    toggleNavbar: (state, action) => {
        state.show = action.payload;
    },
    setMenuData: (state, action) => {
      const menu = action.payload;
      state.menu = menu;
      state.ids = prepareIds(menu);
    },
    setTopOffset: (state, action) => {
      state.topOffset = action.payload;
    },
    setIds: (state, action) => {
      const { id, code } = action.payload;
      state.ids[code] = id;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(initMenu.fulfilled, (state, action) => {
      const menu = action.payload;
      state.menu = menu;
      state.ids = prepareIds(menu);
    });
    builder.addCase(selectModule.fulfilled, (state, action) => {
      state.menu.module = action.payload;
    });
  },
});

export const {
  toggleNavbar,
  setMenuData,
  setTopOffset,
  setIds,
} = navigationBarSlice.actions;
export default navigationBarSlice.reducer;
