import { useSelector } from 'react-redux';
import { useCallback } from 'react';
import { systemNameSelector } from 'store/defaults/defaults.selectors';

export const usePageTitle = () => {
    const systemName = useSelector(systemNameSelector);
    const setTitle = useCallback((title) => {
        if (title === undefined) {
            document.title = `Error occured | ${systemName}`;
        } else {
            document.title = `${title} | ${systemName}`;
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
    return [setTitle];
};

export const setPageBackgroundColor = (color) => {
    document.body.style = `background-color: ${color};`;
};

export const scrollTo = (x, y) => {
    window.scrollTo(x, y);
};

export const scrollTop = () => {
    scrollTo(0, 0);
};
