import { getBaseUrlMCAT } from 'clientUrls';

export const addGetTaskUrl = (contextData) => {
    let url = 'api/tasks/task';

    if (contextData.suggestedTaskId) {
        url += `?suggestedTaskId=${contextData.suggestedTaskId}`;
    }
    if (contextData.questionToSurveyId) {
        url += `&questionToSurveyId=${contextData.questionToSurveyId}`;
    }
    if (contextData.auditId) {
        url += `&auditId=${contextData.auditId}`;
    }
    if (contextData.ncrId) {
        url += contextData.suggestedTaskId ? '&' : '?';
        url += `ncrId=${contextData.ncrId}`;
    }

    url += url.includes('?') ? '&' : '?';
    url += `context=${contextData.context}`;

    return url;
};

export const editGetTaskUrl = (taskId, context) => {
    const url = `api/tasks/task-edit?taskId=${taskId}&context=${context}`;

    return url;
};

export const TaskPillUrl = (taskId, contextData) => {
    let url = `api/tasks/taskpill?taskId=${taskId}`;

    if (contextData.ncrId) {
        url += `&ncrId=${contextData.ncrId}`;
    }

    if (contextData.auditId) {
        url += `&auditId=${contextData.auditId}`;
    }

    return url;
};

export const taskPageUrl = (taskIdNumber) => `${getBaseUrlMCAT()}/Tasks/Task/Edit/${taskIdNumber}`;
export const editTaskUrl = () => 'api/tasks/updatetask';
export const deleteTaskUrl = () => 'api/tasks/deletetask';
export const addTaskUrl = () => 'api/tasks/task';
export const addSupplierUrl = () => `${getBaseUrlMCAT()}/Suppliers/Browse/Add`;
export const suppliersUrl = () => 'api/tasks/suppliers';
