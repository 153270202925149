export const subscribe = (element, event, callback) => {
    element?.addEventListener && element.addEventListener(event, callback);
};

export const unsubscribe = (element, event, callback) => {
    element?.removeEventListener && element.removeEventListener(event, callback, false);
};

export const dispatchEvent = (element, event, value) => {
    const ev = document.createEvent('CustomEvent');
    ev.initCustomEvent(event, true, true, value);
    element.dispatchEvent(ev);
};
