import { partsRoutes } from './routes/partsRoutes';
import { decisionMatrixRoutes } from './routes/decisionMatrixRoutes';
import { rfxRoutes } from './routes/rfxRoutes';
import { ncrRoutes } from './routes/ncrRoutes';
import { auditRoutes } from './routes/auditRoutes';
import { prmRoutes } from './routes/prmRoutes';
import { suppliersRoutes } from './routes/suppliersRoutes';
import { rolloutsRoutes } from './routes/rolloutsRoutes';

export const routesWithLayout = [
    ...auditRoutes,
    ...ncrRoutes,
    ...partsRoutes,
    ...decisionMatrixRoutes,
    ...rfxRoutes,
    ...suppliersRoutes,
    ...prmRoutes,
    ...rolloutsRoutes,
];
