import { useMOTMutation } from 'hooks/useMOTMutation';
import { useDispatch } from 'react-redux';
import { toggleSpinner } from 'store/spinner/spinner.slice';
import MOTApi, { isInlineSuccess } from 'utils/motApi';
import { changeSupplierBUUrl } from '../../urls';
import { refreshUserUrl } from './supplier-change-bu-picker.urls';

function hasNumberInPath(url) {
    const urlObj = new URL(url);
    const path = urlObj.pathname;
    const segments = path.split('/');

    const lastSegment = segments.pop() || segments.pop();
    return !isNaN(lastSegment) && lastSegment.trim() !== '';
}

function hasNumberInQuery(url) {
    const urlObj = new URL(url);
    const params = urlObj.searchParams;

    for (const [key, value] of params) {
        if (!isNaN(value) && value.trim() !== '' && key.toLocaleLowerCase() !== 'tab') {
            return true;
        }
    }

    return false;
}

export const useChangeSupplierBU = () => {
    const dispatch = useDispatch();
    const change = useMOTMutation(async (supplierId) => {
        toggleSpinner(true, dispatch);
        const result = await MOTApi.post(changeSupplierBUUrl(), { supplierId });
        return result;
    }, {
        onSuccess: (resp) => {
            if (isInlineSuccess(resp)) {
                const url = window.location.href;
                if (!hasNumberInPath(url) && !hasNumberInQuery(url)) {
                    window.location.reload();
                    return;
                }

                window.location.href = refreshUserUrl();
            }
        },
    });

    return change;
};
