/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    commonTextErrorSelector,
    commonTextSuccessSelector,
    commonTextWarningSelector,
 currentLanguageSelector,
} from 'store/translations/translations.selectors';
import { colorNames } from 'app/app.styles';
import { deleteAlert } from 'store/notification-bar/notification-bar.slice';
import { notificationBarNotificationsSelector } from 'store/notification-bar/notification-bar.selectors';
import { topOffsetNavigationBarSelector } from 'store/navigation-bar/navigation-bar.selectors';
import { NotificationBarContainer, NotificationBarContent, NotificationBarWrapper } from './notification-bar.styles';

export const NotificationTypes = {
    SUCCESS: 0,
    WARNING: 1,
    ERROR: 2,
};

const NotificationBar = () => {
    const userLang = useSelector(currentLanguageSelector);
    const topOffset = useSelector(topOffsetNavigationBarSelector);
    const notifications = useSelector(notificationBarNotificationsSelector);
    const warningText = useSelector(commonTextWarningSelector(userLang));
    const errorText = useSelector(commonTextErrorSelector(userLang));
    const successText = useSelector(commonTextSuccessSelector(userLang));

    const [notification, setNotification] = useState({ type: '', message: '' });
    const [show, setShow] = useState(false);
    const dispatch = useDispatch();
    const duration = notification.type === NotificationTypes.ERROR ? 8 : 4;
    const timeoutRef = useRef(null);

    useEffect(() => {
        clearTimeout(timeoutRef.current);
        if (notifications.length > 0) {
            const notificationToProcess = notifications[notifications.length - 1];
            if (notificationToProcess.guid) {
                dispatch(deleteAlert(notificationToProcess.guid));
            }

            setNotification(notificationToProcess);
            setShow(true);
        } else {
            timeoutRef.current = setTimeout(() => {
                setShow(false);
            }, duration * 1000);
        }
    }, [notifications, notification.type, notification.guid, dispatch, duration]);

    let defaultMessage = '';
    let color = '';

    switch (notification.type) {
        case NotificationTypes.ERROR:
            if (!notification.message) {
                defaultMessage = errorText;
            }
            color = colorNames.ZenDarkRed;
            break;
        case NotificationTypes.WARNING:
            if (!notification.message) {
                defaultMessage = warningText;
            }
            color = colorNames.ZenYellowLight;
            break;
        case NotificationTypes.SUCCESS:
            if (!notification.message) {
                defaultMessage = successText;
            }
            color = colorNames.ZenGreenLight;
            break;
        default:
            defaultMessage = '';
        }

    return show ? (
        <NotificationBarContainer duration={duration} key={notification.guid} topOffset={topOffset}>
            <NotificationBarWrapper backgroundColor={color}>
                <NotificationBarContent>
                    {notification.message || defaultMessage}
                </NotificationBarContent>
            </NotificationBarWrapper>
        </NotificationBarContainer>

    ) : null;
};

export default NotificationBar;
