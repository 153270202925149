/* eslint-disable react-hooks/exhaustive-deps */
import { ComboBox as KendoComboBox } from '@progress/kendo-react-dropdowns';
import React, {
    useCallback,
    useState,
    useEffect,
    useRef,
    cloneElement,
} from 'react';
import { useSelector } from 'react-redux';
import store from 'store/store';
import { commonTextLoadingSelector } from 'store/translations/translations.selectors';
import MOTApi from 'utils/motApi';
import { ComboBoxBase } from './combo-box-base.component';
import { ComboBoxGlobalStyles, ComboBoxWrapper } from './combo-box.styles';

// eslint-disable-next-line no-underscore-dangle
export const getComboBoxText = (e) => e.target._input.value;

export const customTemplate = (li, template) => cloneElement(li, li.props, template);
export const customValueWithItemTemplate = (element, template) => <div className='d-flex'>{template} {element}</div>;

// OBSOLETE USE ComboboxLocalData or ComboboxRemoteData | use when old minlength is forced and virtualization not available
export const ComboBox = React.forwardRef((props, ref) => {
    const {
        textField,
        valueField,
        url,
        width,
        postData,
        data,
        value,
        minLength, // obsolete - virtualization should be set for higher number of data
        autoBind = true,
        onDataBound,
        onChange,
    } = props;

    const loadingSnippet = useSelector(commonTextLoadingSelector(store.getState()));
    const emptyItem = {
        [textField]: `${loadingSnippet} ...`,
        [valueField]: 0,
    };
    const pendingRequest = useRef();
    const requestStarted = useRef(false);
    const [comboBoxData, setData] = useState(data);
    const [comboBoxValue, setValue] = useState(value);
    const [filter, setFilter] = useState('');
    const skipRef = useRef(0);
    const initialRequestRef = useRef(!autoBind);

    const requestData = useCallback((skip, text) => {
        if (initialRequestRef.current) {
            initialRequestRef.current = false;
            return;
        }

        if (requestStarted.current) {
                clearTimeout(pendingRequest.current);
                pendingRequest.current = setTimeout(() => {
                requestData(skip, text);
            }, 50);
            return;
        }

        requestStarted.current = true;
        MOTApi.post(url, { ...postData, text })
            .then((response) => {
                requestStarted.current = false;

                const items = [];
                response.data.forEach((element) => {
                    const item = {
                        ...element,
                    };
                    items.push(item);
                });
                if (skip === skipRef.current) {
                    setData(items);
                    if (onDataBound) {
                        onDataBound(ref, items);
                    }
                }
            });
    }, []);
    useEffect(() => {
        if (ref && ref.current) {
            // eslint-disable-next-line no-param-reassign
            ref.current.requestData = requestData;
        }
    }, []);

    useEffect(() => {
        if (!minLength || filter.length >= minLength) {
            requestData(0, filter);
        }
    }, [requestData, minLength]);

    const onFilterChange = useCallback((event) => {
        const newFilter = event.filter.value;
        if (!minLength || newFilter.length >= minLength) {
            requestData(0, newFilter);
            skipRef.current = 0;
        } else {
            setData([]);
        }
        setFilter(newFilter);
    }, []);

    const onChangeComboBox = useCallback((event) => {
        const newValue = event.target.value;
        if (newValue && newValue[textField] === emptyItem[textField]) {
            return;
        }

        setValue(newValue);
        if (onChange) {
            onChange(event);
        }
    }, []);

    return (<ComboBoxBase
            {...props}
            ref={ref}
            data={comboBoxData}
            value={comboBoxValue}
            onChange={onChangeComboBox}
            dataItemKey={valueField}
            textField={textField}
            filterable={true}
            suggest={false}
            onFilterChange={onFilterChange}
            style={{
                width,
            }}
        />
    );
});
