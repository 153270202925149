import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    lang: null,
    commonTranslations: [],
};

export const translationsSlice = createSlice({
  name: 'translations',
  initialState,
  reducers: {
    setLang: (state, action) => {
      state.lang = action.payload.toLowerCase();
    },
    initLang: (state, action) => {
      const { lang, commonTranslations } = action.payload;
      const langLower = lang.toLowerCase();
      state.lang = langLower;
      state.commonTranslations = {
          ...state.commonTranslations,
          [langLower]: commonTranslations,
      };
    },
    setCommonTranslations: (state, action) => {
      const { translations, userLanguage } = action.payload;
      const langLower = userLanguage.toLowerCase();

      state.commonTranslations = {
          ...state.commonTranslations,
          [langLower]: translations,
      };
    },
  },
});

export const { setLang, initLang, setCommonTranslations } = translationsSlice.actions;
export default translationsSlice.reducer;
