import { ensureUrlSlash } from 'utils/string';

export const getBaseUrlMOTBackend = () => ensureUrlSlash(window.env.BASE_URL_MOT_BACKEND_GATEWAY_WEB);

export const getBaseUrlMOTFrontend = () => ensureUrlSlash(`${window.location.protocol}//${window.location.host}`);

export const getBaseMCATOAuth = () => ensureUrlSlash(window.env.BASE_URL_MCAT_OAUTH);

export const getBaseMCAT = () => ensureUrlSlash(window.env.BASE_URL_MCAT);

export const getBasePPAP = () => ensureUrlSlash(window.env.Base_URL_PPAP);
