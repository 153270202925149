import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
    richWysiwygInfoPage: false,
    sectionsDisplayShadow: false,
    supportWidget: null,
    supportWidgetMobile: false,
};

export const controlsSlice = createSlice({
    name: 'controls',
    initialState: INITIAL_STATE,
    reducers: {
      setControls: (state, action) => {
        const { richWysiwygInfoPage, sectionsDisplayShadow, supportWidget } = action.payload;
        state.richWysiwygInfoPage = richWysiwygInfoPage;
        state.sectionsDisplayShadow = sectionsDisplayShadow;
        state.supportWidget = supportWidget;
      },
      setSupportWidgetMobile: (state, action) => {
        state.supportWidgetMobile = action.payload;
      },
    },
});

export const { setControls, setSupportWidgetMobile } = controlsSlice.actions;
export default controlsSlice.reducer;
