import { ProtectedRoute } from 'components/auth/protected-route.component';
import { lazy } from 'react';
import { LazyWrapper } from '../routes';

const RolloutLazy = lazy(() => import('routes/rollouts/rollout/rollout.component'));

export const rolloutsRoutes = [
    // rollout info page
    { path: ':clientPrefix/rollouts/rollout/:id', component: <LazyWrapper><ProtectedRoute><RolloutLazy/></ProtectedRoute></LazyWrapper> },
];
