import { ProtectedRoute } from 'components/auth/protected-route.component';
import { lazy } from 'react';
import { LazyWrapper } from '../routes';

const RfxScenarioListLazy = lazy(() => import('routes/rfx/scenario/list/scenario.component'));
const RfXEventLazy = lazy(() => import('routes/rfx/event/event.component'));
const RfXSupplierEventLazy = lazy(() => import('routes/rfx/supplierEvent/supplier-event.component'));

export const rfxRoutes = [
    // admin scenario
    { path: ':clientPrefix/rfx/scenario/list', component: <LazyWrapper><ProtectedRoute><RfxScenarioListLazy/></ProtectedRoute></LazyWrapper> },

    // event index
    { path: ':clientPrefix/rfx/event/index/:eventId', component: <LazyWrapper><ProtectedRoute><RfXEventLazy/></ProtectedRoute></LazyWrapper> },

    // supplier event index
    {
        path: ':clientPrefix/rfx/supplierevent/index/:supplierEventId',
        component: <LazyWrapper><ProtectedRoute><RfXSupplierEventLazy/></ProtectedRoute></LazyWrapper>,
    },
];
