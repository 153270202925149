import { colorNames, footerHeight } from 'app/app.styles';
import styled from 'styled-components';

export const FooterWrapperInner = styled.div`
    font-size: 0.9em
`;

export const FooterWrapper = styled.div`
    height: ${footerHeight}px;
    font-size: 0.9em;
    padding: 4px 19px 4px 19px;
    color: var(${colorNames.ZenDarkGrey});
`;

export const FooterCompanyWrapper = styled.span`
    color: var(${colorNames.ZenCyan});
    margin-right: 6px;
`;

export const FooterLinkWrapper = styled.span`
    padding-left: 10px;
    a {
        text-decoration: none;
        color: var(${colorNames.ZenCyan});
        &:hover {
            text-decoration: underline;
        }
    }
`;

export const FooterLicenseWrapper = styled.span`
    color: var(${colorNames.ZenDarkRed});
    text-transform: uppercase;
`;

export const FooterCopyrightWrapper = styled.span`
    color: var(${colorNames.ZenGrey});
    float: right;
    padding-right: 10px;
`;
