import { LazyWrapper } from 'app/routes';
import { lazy, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showInternalErrorSelector } from 'store/internal-error-500-action/internal-error-500-action.selectors';
import { toggleSpinner } from 'store/spinner/spinner.slice';

const InternalError500 = lazy(() => import('./internal-error.component'));

export const InternalError500Action = () => {
    const show = useSelector(showInternalErrorSelector);
    const dispatch = useDispatch();

    useEffect(() => {
        if (show) {
            toggleSpinner(false, dispatch);
        }
    }, [show, dispatch]);

    return (show ? <LazyWrapper><InternalError500/></LazyWrapper> : null);
};
