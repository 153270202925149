import MOTApi from 'utils/motApi';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { currentLanguageSelector } from 'store/translations/translations.selectors';

export const useTranslations = (urlFunc, lazyLoad) => {
    const userLanguage = useSelector(currentLanguageSelector);
    const [translations, setTranslations] = useState({});

    useEffect(() => {
        const isLazyLoadInitialized = (lazyLoad && Object.keys(translations).length) || !lazyLoad;

        if (isLazyLoadInitialized) {
            const url = urlFunc(userLanguage);
            if (!translations[userLanguage]) {
                MOTApi.get(url).then((data) => {
                    setTranslations((prev) => ({
                            ...prev,
                            [userLanguage]: data.data,
                        }));
                });
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lazyLoad, urlFunc, userLanguage]);

    return {
        translations: translations[userLanguage] || {},
        setTranslations,
        userLanguage,
    };
};
