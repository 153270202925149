import { LazyWrapper } from 'app/routes';
import { lazy } from 'react';
import { useSelector } from 'react-redux';
import { signInRedirectSelector, signInSelector } from 'store/app/appSetter.Selectors';

const SignInLazy = lazy(() => import('./signIn-lazy.component'));

export const SignIn = () => {
    const signIn = useSelector(signInSelector);
    const redirectUrl = useSelector(signInRedirectSelector);

    return (signIn ? <LazyWrapper><SignInLazy signIn={signIn} redirectUrl={redirectUrl}/></LazyWrapper> : null);
};
