import { CountryFlagWrapper } from './country-flag.styles';

export const FlagSizes = {
    SMALL: 'ff-sm',
    MEDIUM: 'ff-md',
    LARGE: 'ff-lg',
    XLARGE: 'ff-xl',
};

export const CountryFlag = ({ code, size, className }) => {
    const flag = `fflag fflag-${code} ${size} ${className !== undefined && className !== null && className}`;
    return <CountryFlagWrapper className={flag} code={code}></CountryFlagWrapper>;
};
