import { useSelector } from 'react-redux';
import { supportWidgetSelector } from 'store/controls/controls.selectors';
import { SmartSupportButtonContainer } from './smartsupp-button.styles';

export const SmartSupportButton = (textColor) => {
    const data = useSelector(supportWidgetSelector);
    const {
        position,
        isEnabled,
    } = data || {};
    const shouldBeShow = position === 3;

    // eslint-disable-next-line no-undef
    return shouldBeShow && isEnabled && <SmartSupportButtonContainer textColor={textColor} onClick={() => smartsupp('chat:open')}>
        <i className="fa fa-comments" aria-hidden="true"></i>
    </SmartSupportButtonContainer>;
};
