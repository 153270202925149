import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
    minDate: undefined,
    maxDate: undefined,
    systemName: undefined,
};

export const defaultsSlice = createSlice({
    name: 'defaults',
    initialState: INITIAL_STATE,
    reducers: {
      setDefaults: (state, action) => {
        const { minDate, maxDate, systemName } = action.payload;
        state.minDate = minDate;
        state.maxDate = maxDate;
        state.systemName = systemName;
      },
    },
});

export const { setDefaults } = defaultsSlice.actions;
export default defaultsSlice.reducer;
