import { Row, Col } from 'react-bootstrap';
import { ModuleHeaderCloseActionWrapper, ModuleHeaderWrapper } from '../menu.styles';

export const ModuleHeader = ({
    moduleHeader,
    closeActionName,
    mainAreaTitleColor,
    closeMenu,
}) => (
    <ModuleHeaderWrapper mainAreaTitleColor={mainAreaTitleColor}>
        <Row>
            <Col className='p-0' xs={12}>
                    {moduleHeader}
                    <ModuleHeaderCloseActionWrapper mainAreaTitleColor={mainAreaTitleColor} onClick={closeMenu}>
                        <i className='fa-solid fa-xmark'></i> <span>{closeActionName}</span>
                    </ModuleHeaderCloseActionWrapper>
            </Col>
        </Row>
    </ModuleHeaderWrapper>
);
