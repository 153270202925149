/* eslint-disable max-len */
import { MenuIconWrapper } from './menu.styles';

export const MenuIcon = (props) => {
  const { menu, textColor, onMenuIconClick } = props;
  const { verticalBarIconHeight, verticalBarIconWidth } = menu?.baseSettings || {};
  const showMenuIcon = menu?.modules?.length > 0 && !menu.hideMenu;

  return (
    showMenuIcon && (
      <MenuIconWrapper
        onClick={onMenuIconClick}
        width={verticalBarIconWidth}
        height={verticalBarIconHeight}
        color={textColor}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 30.000001 25.075502"
        >
          <g transform="translate(-37.85505,0.94141436)" id="g5983">
            <path
              id="path5841"
              d="m 60.729371,3.0565857 v -3.99800006 h 1.539 V 3.0165857 c 0,1.139 0.57,1.729 1.509,1.729 0.939,0 1.509,-0.57 1.509,-1.679 v -4.00800006 h 1.539 V 3.0065857 c 0,2.119 -1.189,3.158 -3.068,3.158 -1.879,0 -3.028,-1.049 -3.028,-3.108 z"
            />
            <path
              id="path5839"
              d="m 53.039371,-0.94141436 h 1.419 l 3.278,4.30800006 v -4.30800006 h 1.519 V 6.0545857 h -1.308 l -3.388,-4.448 v 4.448 h -1.52 z"
            />
            <path
              id="path5837"
              d="m 46.439371,-0.94141436 h 5.277 v 1.36900001 h -3.748 V 1.8465857 h 3.298 v 1.369 h -3.298 v 1.469 h 3.798 v 1.369 h -5.327 z"
            />
            <path
              id="path372"
              d="m 37.859371,-0.94141436 h 1.659 l 1.839,2.95800006 1.839,-2.95800006 h 1.659 V 6.0545857 h -1.529 v -4.567 l -1.969,2.988 h -0.04 l -1.949,-2.958 v 4.538 h -1.509 z"
            />
          </g>
          <rect
            ry="0.80000001"
            rx="0.89999998"
            y="12.342988"
            x="0"
            height="4.4978685"
            width="28.974642"
            id="rect5958"
          />
          <rect
            id="rect5960"
            width="28.974642"
            height="4.4978685"
            x="0"
            y="12.342988"
            rx="0.89999998"
            ry="0.80000001"
          />
          <rect
            ry="0.80000001"
            rx="0.89999998"
            y="22.332441"
            x="0"
            height="4.4978685"
            width="28.974642"
            id="rect5960-8"
          />
        </svg>
      </MenuIconWrapper>
    )
  );
};
