import { Col } from 'react-bootstrap';
import { logError } from 'utils/logger';
import { McatMenuSectionTypes } from 'utils/mcat-enums/system.enums';
import { ModuleColumnWrapper } from '../menu.styles';
import { ActionSection } from './sections/action-section.component';
import { LinkGroupSection } from './sections/link-group-section.component';
import { KeyAssessmentSection } from './sections/key-assessment-section.component';
import { SearchSection } from './sections/search-section.component';
import { SearchWithNameSection } from './sections/search-with-name-section.component';

export const ModuleColumn = ({
    column,
    documentPreviewUrl,
    editUserUrl,
    color,
    code,
}) => (<Col lg={3} md={4} sm={6} xs={12} className='p-0'>
        <ModuleColumnWrapper>
            {
                column.sections.map((section, index) => {
                    switch (section.type) {
                        case McatMenuSectionTypes.Action:
                            return <ActionSection code={code} key={index} section={section} color={color}/>;
                        case McatMenuSectionTypes.LinkGroup:
                            return <LinkGroupSection key={index} section={section} color={color}/>;
                        case McatMenuSectionTypes.KeyAssessment:
                            return <KeyAssessmentSection code={code} key={index} section={section} color={color}/>;
                        case McatMenuSectionTypes.Search:
                            return <SearchSection
                                editUserUrl={editUserUrl}
                                documentPreviewUrl={documentPreviewUrl}
                                key={index}
                                code={code}
                                section={section}
                                color={color}
                            />;
                        case McatMenuSectionTypes.Search_WithName:
                            return <SearchWithNameSection
                                editUserUrl={editUserUrl}
                                documentPreviewUrl={documentPreviewUrl}
                                key={index}
                                actcodeion={code}
                                section={section}
                                color={color}
                            />;
                        default:
                            logError('Not Implemented');
                            return null;
                    }
                })
            }
        </ModuleColumnWrapper>
    </Col>);
