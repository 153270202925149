import { configureStore } from '@reduxjs/toolkit';
import userReducer from './user/user.slice';
import appSetterReducer from './app/appSetter.slice';
import appReducer from './app/app.slice';
import defaultsReducer from './defaults/defaults.slice';
import translationsReducer from './translations/translations.slice';
import notificationReducer from './notification-bar/notification-bar.slice';
import notificationSmallReducer from './notification-bar-small/notification-bar-small.slice';
import notFoundReducer from './not-found-404-action/not-found-404-action.slice';
import internalErrorReducer from './internal-error-500-action/internal-error-500-action.slice';
import spinnerReducer from './spinner/spinner.slice';
import navigationBarReducer from './navigation-bar/navigation-bar.slice';
import pageHeaderReducer from './page-header/page-header.slice';
import controlsReducer from './controls/controls.slice';
import footerReducer from './footer/footer.slice';

const reducer = {
    appSetter: appSetterReducer,
    user: userReducer,
    app: appReducer,
    defaults: defaultsReducer,
    translations: translationsReducer,
    notificationsBar: notificationReducer,
    notificationsBarSmall: notificationSmallReducer,
    spinner: spinnerReducer,
    notFound: notFoundReducer,
    internalError: internalErrorReducer,
    navigationBar: navigationBarReducer,
    pageHeader: pageHeaderReducer,
    controls: controlsReducer,
    footer: footerReducer,
};

const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredPaths: ['pageHeader'],
        ignoredActions: ['pageHeader/setPageHeader'],
      },
    }),
});

export default store;
