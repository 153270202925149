import axios from 'axios';
import { createSmallNotificationAction } from 'components/notification-bar-small/notification-bar-small.actions';
import { createNotificationAction } from 'components/notification-bar/notification-bar.actions';
import { createSmallAlert } from 'store/notification-bar-small/notification-bar-small.slice';
import { NotificationTypes } from 'components/notification-bar/notification-bar.component';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toggleInternalError } from 'store/internal-error-500-action/internal-error-500-action.slice';
import { toggleNotFound } from 'store/not-found-404-action/not-found-404-action.slice';
import { createAlert } from 'store/notification-bar/notification-bar.slice';
import { getBaseMCAT, getBaseUrlMOTBackend } from 'urls';
import { toggleSpinner } from 'store/spinner/spinner.slice';
import { toggleNavbar } from 'store/navigation-bar/navigation-bar.slice';
import { appClientSelector } from 'store/app/app.selectors';
import { setAnyViewInitialized, setSignIn } from 'store/app/appSetter.slice';

const UNAUTHORIZED = 401;
const FORBIDDEN = 403;
const TEMPREDIRECT = 307;
const ERROR = 500;
const SUCCESS = 200;
const RESET = 205;
const RESET_CONTENTCHANGED = 299;
const NOTFOUND = 404;

export const NotifyTypes = {
    SMALL: 'small',
    LARGE: 'large',
    NONE: 'none',
};

const MOTApi = axios.create({
    baseURL: getBaseUrlMOTBackend(),
    timeout: 600000, // 10 minutes
    withCredentials: true,
    headers: {
        RequestUrl: window.location.href,
    },
});

export const isInlineSuccess = (response) => response?.status === SUCCESS
    && (response?.data?.notificationBarJson?.isSuccess || response?.data?.notificationBarSmallJson?.isSuccess);

export const resetValueAfterInlineSaveFail = (ref, response) => {
    if (!isInlineSuccess(response) && ref && ref.current && ref.current.setValue) {
        ref.current.setValue(null);
    }
};

export const formValidation = (errors) => Object.entries(errors).filter(([, x]) => x.length > 0).length === 0;

export const formIsValid = (response, dispatch, notificationTypeSuccess) => {
    const success = formValidation(response.data.formErrors);
    if (success) {
        switch (notificationTypeSuccess) {
            case NotifyTypes.SMALL:
                dispatch(createSmallAlert(createSmallNotificationAction(null, NotificationTypes.SUCCESS)));
                break;
            case NotifyTypes.LARGE:
                dispatch(createAlert(createNotificationAction(null, NotificationTypes.SUCCESS)));
                break;
            case NotifyTypes.NONE:
                break;
            default:
                break;
        }
    }
    return success;
};

export const MOTAPIInterceptorInitializer = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const clientPrefix = useSelector(appClientSelector);

    useEffect(() => {
        function requestHandler(request) {
            return request;
        }

        const responseHandler = (response) => {
            const { status } = response;

            if (status === RESET || status === RESET_CONTENTCHANGED) {
                document.location.reload();
            }

            if (response?.data?.initView) {
                dispatch(setAnyViewInitialized(true));
            }

            if (response?.data?.notificationBarJson) {
                const { message, type } = response.data.notificationBarJson;
                const alert = createNotificationAction(message, type);
                dispatch(createAlert(alert));
            }

            if (response?.data?.notificationBarSmallJson) {
                const { message, type } = response.data.notificationBarSmallJson;
                const alert = createSmallNotificationAction(message, type);
                dispatch(createSmallAlert(alert));
            }

            return response;
        };

        const errorHandler = (error) => {
            const { status } = error.response;
            if (status === UNAUTHORIZED) {
                const redirectUrl = window.location.href;
                dispatch(setSignIn({ value: true, redirectUrl }));
            }

            if (status === FORBIDDEN) {
                window.location.href = getBaseMCAT();
            }

            if (status === TEMPREDIRECT) {
                const { sameHost, url, domainPrefix } = error.response.data;
                if (sameHost) {
                    if (clientPrefix !== domainPrefix) {
                        window.location.href = url;
                    } else {
                        navigate(url);
                    }
                } else {
                    window.location.href = url;
                }
            }

            if (status === NOTFOUND) {
                dispatch(toggleNavbar(false));
                dispatch(toggleNotFound(true));
                toggleSpinner(false, dispatch);
            }

            const serviceId = error?.response?.data?.serviceId;
            if (status === ERROR && serviceId) {
                dispatch(toggleNavbar(false));
                dispatch(toggleInternalError({ show: true, serviceId }));
                toggleSpinner(false, dispatch);
            }

            if (status === ERROR) {
                if (error?.response?.data?.notificationBarJson) {
                    const { message } = error.response.data.notificationBarJson;
                    const alert = createNotificationAction(message, NotificationTypes.ERROR);
                    dispatch(createAlert(alert));
                } else if (error?.response?.data?.notificationBarSmallJson) {
                    const { message } = error.response.data.notificationBarSmallJson;
                    const alert = createSmallNotificationAction(message, NotificationTypes.ERROR);
                    dispatch(createSmallAlert(alert));
                } else {
                    const alert = createNotificationAction(error?.response?.data?.notificationBarJson?.message,
                        NotificationTypes.ERROR);
                    dispatch(createAlert(alert));
                }
                toggleSpinner(false, dispatch);
            }

            return error;
        };

        MOTApi.interceptors.request.use(
            (request) => requestHandler(request),
            (error) => errorHandler(error),
        );

        MOTApi.interceptors.response.use(
            (response) => responseHandler(response),
            (error) => errorHandler(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};

export default MOTApi;
