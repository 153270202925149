import styled from 'styled-components';

export const CountryFlagContainer = styled.div`
    display: flex;
    align-items: ${(props) => (props.align ? props.align : 'center')};
`;

export const CountryFlagWrapper = styled.div`
    ${(props) => (props.code === 'zu' ? 'filter: brightness(0) invert(1);' : '')};

    &.fflag.ff-sm {
        min-width:18px;
    }
    &.fflag.ff-md {
        min-width:27px;
    }
    &.fflag.ff-lg {
        min-width:42px;
    }
    &.fflag.ff-xl {
        min-width:60px;
    }
`;
