import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import MOTapi from 'utils/motApi';
import { logOutCallbackUrl, loginUrl, logOutUrl } from 'routes/account/urls';
import { setLang } from 'store/translations/translations.slice';

const INITIAL_STATE = {
  currentUser: null,
  userIsFetching: false,
  signIn: false,
  signOut: false,
  redirectUrl: '',
};

export const logOutCallback = createAsyncThunk('user/logOutCallback', async (sid) => {
  const resp = await MOTapi.get(logOutCallbackUrl(sid));

  return resp.data;
});

export const login = createAsyncThunk('user/login', async ({ code, client }, { dispatch }) => {
  const resp = await MOTapi.get(loginUrl(code, client));
  dispatch(setLang(resp.data.language));

  return resp.data;
});

export const logOut = createAsyncThunk('user/logout', async (client) => {
  const resp = await MOTapi.post(logOutUrl(client));

  return resp.data;
});

export const userSlice = createSlice({
    name: 'user',
    initialState: INITIAL_STATE,
    reducers: {
      setCurrentUser: (state, action) => {
        state.currentUser = action.payload;
      },
      setNumberDateFormat: (state, action) => {
        state.currentUser = {
          ...state.currentUser,
          numberDateFormat: action.payload,
        };
      },
      setSignOut: (state, action) => {
        state.signOut = action.payload;
      },
    },
    extraReducers: (builder) => {
      builder.addCase(logOutCallback.fulfilled, (state, action) => {
        state.currentUser = action.payload;
      });
      builder.addCase(login.fulfilled, (state, action) => {
        state.currentUser = action.payload;
        state.userIsFetching = false;
      });
      builder.addCase(login.pending, (state) => {
        state.userIsFetching = true;
      });
      builder.addCase(login.rejected, (state) => {
        state.userIsFetching = false;
      });
    },
});

export const { setCurrentUser, setSignOut, setNumberDateFormat } = userSlice.actions;
export default userSlice.reducer;
