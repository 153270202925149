import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  signIn: false,
  anyViewInitialized: false,
};

export const appSetterSetterSlice = createSlice({
    name: 'appSetter',
    initialState: INITIAL_STATE,
    reducers: {
      setSignIn: (state, action) => {
        const { value, redirectUrl } = action.payload;
        state.signIn = value;
        state.redirectUrl = redirectUrl;
      },
      setAnyViewInitialized: (state, action) => {
        state.anyViewInitialized = action.payload;
      },
    },
});

export const { setSignIn, setAnyViewInitialized } = appSetterSetterSlice.actions;
export default appSetterSetterSlice.reducer;
