import { useDispatch } from 'react-redux';
import { useQueryClient } from 'react-query';
import { DropDownListWithQuery } from 'components/kendo/drop-down-list/drop-down-list-with-query.component';
import { LanguagePickerValueKey } from '../language-picker/language-picker.queries';
import { languageOnChange } from '../language-picker/language-picker.component';
import {
    UserProfileMenuElementWrapper,
    UserProfileMenuHeaderWrapper,
    UserProfileMenuItemWrapper,
    UserProfileMenuLanguageWrapper,
} from './user-profile-menu.styles';

export const UserProfileMenuLanguage = ({
    elem,
    mainAreaSectionElementFontColor,
    isLoggedIn,
    isDummy,
    setShowMenu,
}) => {
    const value = elem.languages.find((lang) => lang.isCurrentlySelected);
    const dispatch = useDispatch();
    const queryClient = useQueryClient();

    return <UserProfileMenuItemWrapper>
        <UserProfileMenuLanguageWrapper>
            <UserProfileMenuHeaderWrapper mainAreaSectionElementFontColor={mainAreaSectionElementFontColor}>{elem.name}</UserProfileMenuHeaderWrapper>
                <UserProfileMenuElementWrapper mainAreaSectionElementFontColor={mainAreaSectionElementFontColor}>
                    <DropDownListWithQuery
                        queryKey={LanguagePickerValueKey()}
                        data-test-id='user-profile-menu-language-picker'
                        className='user-profile-menu-language-picker'
                        value={value}
                        textField={'text'}
                        dataItemKey={'code'}
                        data={elem.languages}
                        onChange={(e) => {
                            languageOnChange(e, isLoggedIn, isDummy, dispatch, queryClient, () => setShowMenu(false));
                        }}
                    />
            </UserProfileMenuElementWrapper>
        </UserProfileMenuLanguageWrapper>
    </UserProfileMenuItemWrapper>;
};
