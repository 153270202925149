import { Notification, NotificationGroup } from '@progress/kendo-react-notification';
import { colorNames } from 'app/app.styles';
import styled from 'styled-components';

export const NotificationGroupContainer = styled(NotificationGroup)`
    bottom: 15px;
    left: 15px;
    z-index: 11000001;
`;

export const NotificationContainer = styled(Notification)`
    font-size: 12px;
    display: flex;
    color: var(${colorNames.ZenWhite});
    background-color: ${(props) => `var(${props.color})`};
`;
