import { CountryFlag, FlagSizes } from 'components/country-flag/country-flag.component';
import React from 'react';
import { useDispatch } from 'react-redux';
import { setLang } from 'store/translations/translations.slice';
import MOTApi, { isInlineSuccess } from 'utils/motApi';
import { toggleSpinner } from 'store/spinner/spinner.slice';
import { useQueryClient } from 'react-query';
import { DropDownListWithQuery } from 'components/kendo/drop-down-list/drop-down-list-with-query.component';
import { LanguageItemContainer } from './language-picker.styles';
import { changeLanguageUrl } from '../../urls';
import { LanguagePickerValueFlagKey, LanguagePickerValueKey } from './language-picker.queries';

const itemRender = (li, itemProps) => {
    const itemChildren = (
        <LanguageItemContainer>
            <CountryFlag className ='me-1'size={FlagSizes.SMALL} code={itemProps.dataItem?.code}/>
            {itemProps.dataItem.text}
        </LanguageItemContainer>);

    return React.cloneElement(li, li.props, itemChildren);
};

const valueRender = (element, item) => <CountryFlag size={FlagSizes.SMALL} code={item?.code}/>;

export const languageOnChange = (event, isLoggedIn, isDummy, dispatch, queryClient, callback) => {
    const newLanguage = event?.value?.code;
    toggleSpinner(true, dispatch);
    callback && callback();
    if (isLoggedIn && !isDummy) {
        const dto = {
            code: newLanguage,
        };
        MOTApi.post(changeLanguageUrl(), dto).then((resp) => {
            if (isInlineSuccess(resp)) {
                dispatch(setLang(newLanguage));
                toggleSpinner(true, dispatch);
            }
            queryClient.setQueryData(LanguagePickerValueKey(), event.value);
            queryClient.setQueryData(LanguagePickerValueFlagKey(), event.value);
        });
    } else {
        dispatch(setLang(newLanguage));
        toggleSpinner(true, dispatch);
        queryClient.setQueryData(LanguagePickerValueKey(), event.value);
        queryClient.setQueryData(LanguagePickerValueFlagKey(), event.value);
    }

    queryClient.clear();
};

export const LanguagePicker = (props) => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();

    const {
        currentLanguage,
        languages,
        isLoggedIn,
        isDummy,
     } = props;
    const value = languages.find((x) => x.code.toLowerCase() === currentLanguage);

    return <div className='mb-2'>
        <DropDownListWithQuery
            queryKey={LanguagePickerValueFlagKey()}
            height='unset'
            data-test-id='language-picker'
            popupSettings={{
                width: '240px',
                height: '100%',
                className: 'navigation-dropdown-popup',
            }}
            data={languages}
            textField='text'
            dataItemKey='code'
            value={value}
            itemRender={itemRender}
            valueRender={valueRender}
            onChange={(e) => languageOnChange(e, isLoggedIn, isDummy, dispatch, queryClient)}
        />
    </div>;
};
