import { load, loadMessages } from '@progress/kendo-react-intl';
import likelySubtags from 'cldr-core/supplemental/likelySubtags.json';
import currencyData from 'cldr-core/supplemental/currencyData.json';
import weekData from 'cldr-core/supplemental/weekData.json';
import { setNumberDateFormat } from 'store/user/user.slice';

export const defaultCulture = 'en';
export const defaultLanguage = 'en-US';

export const bootstrapBreakpoints = {
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400,
};

export const toUpperLanguageAfterDash = (language) => {
    if (!language) {
        return defaultLanguage;
    }

    const split = language.split('-');
    let parsedLanguage = split[0];
    if (language.includes('-')) {
        parsedLanguage += '-';
        parsedLanguage += split[1].toUpperCase();
    }

    return parsedLanguage;
};

const loadKendoCulture = async (cultureFormat) => ({
    caGregorian: await import(`cldr-dates-full/main/${cultureFormat}/ca-gregorian.json`),
    localCurrency: await import(`cldr-numbers-full/main/${cultureFormat}/currencies.json`),
    numbers: await import(`cldr-numbers-full/main/${cultureFormat}/numbers.json`),
    dateFields: await import(`cldr-dates-full/main/${cultureFormat}/dateFields.json`),
});

const loadKendoTranslation = async (language) => {
    const result = await fetch(`${process.env.PUBLIC_URL}/assets/translations/${language}/${language}.json`);
    const translations = await result.json();
    return translations;
};

export const initKendo = async (cultureFormat, language, dispatch) => {
    if (!language || !cultureFormat) {
        return {
            cultureFormat,
        };
    }
    let cultureFormatLoaded = cultureFormat;

    const loadKendoModules = async () => {
        let data;
        try {
            data = await loadKendoCulture(cultureFormat);
        } catch (e) {
            try {
                data = await loadKendoCulture(cultureFormat.split('-')[0]);
                [cultureFormatLoaded] = cultureFormat.split('-');
            } catch (ex) {
                data = await loadKendoCulture(defaultCulture);
                cultureFormatLoaded = defaultCulture;
            }
        }

        if (cultureFormat !== cultureFormatLoaded) {
            dispatch(setNumberDateFormat(cultureFormatLoaded));
        }

        return {
            dateFields: data.dateFields,
            caGregorian: data.caGregorian,
            localCurrency: data.localCurrency,
            numbers: data.numbers,
        };
    };

    const data = await loadKendoModules();
    load(
        likelySubtags,
        currencyData,
        weekData,
        data.dateFields,
        data.caGregorian,
        data.localCurrency,
        data.numbers,
    );

    const loadKendoTranslations = async () => {
        let translations;

        try {
            translations = await loadKendoTranslation(language);
        } catch (e) {
            try {
                translations = await loadKendoTranslation(language.split('-')[0]);
            } catch (ex) {
                translations = await loadKendoTranslation(defaultLanguage);
            }
        }

        return translations;
    };

    const t = await loadKendoTranslations();
    loadMessages(t, language);

    return {
        cultureFormatLoaded,
    };
};
