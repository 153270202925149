import { Icon } from 'components/icon/icon.component';
import styled from 'styled-components';

export const HorizontalBarMenuIcon = styled(Icon)`
    color: ${(props) => props.textColor};
    font-size: 2.5em !important;
    display: block;
    cursor: pointer;
    padding: 0;
    i {
        font-size: unset !important;
    }
`;
