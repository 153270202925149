import { getClientPrefix } from 'app/clientPrefix';

export const loginUrl = (code, client) => `/api/auth/login?code=${code}&client=${client}`;

export const logOutUrl = (client) => `/api/auth/logout?client=${client}`;

export const logOutCallbackUrl = (sid) => `/api/auth/logout/callback?sid=${sid}`;

export const loginFrontEndPath = (returnUrl, state) => {
    const clientPrefix = getClientPrefix(state);
    const basePath = 'account/signin';
    if (clientPrefix) {
        return `${clientPrefix.toLowerCase()}/${basePath}?returnUrl=${returnUrl}`;
    }

    return basePath;
};
