import { fonts } from 'app/app.fonts';
import { ComboBoxWrapper } from 'components/kendo/combo-box/combo-box.styles';
import { Col } from 'react-bootstrap';
import styled from 'styled-components';

export const MenuWrapper = styled.div`
`;

export const MenuIconWrapper = styled.div`
    margin-left: 10px;
    svg {
        fill: ${(props) => props.color};
        width: ${(props) => `${27}px` || props.width};
        height: ${(props) => props.height};
        cursor: pointer;
    }
`;

export const SubMenuItemWrapper = styled.div`
    text-align: center;
    height: 100%;
    box-sizing: border-box;
    padding: ${(props) => props.padding};
    ${(props) => (props.isActive ? `background-color: ${props.verticalBarIconOnHooverBackgroundColor};` : '')}
    &:hover {
        background-color: ${(props) => props.verticalBarIconOnHooverBackgroundColor};
    }
`;

export const SubMenuItemIconWrapper = styled.div`
    margin: 0 auto;
    svg {
        fill: ${(props) => props.color};
        width: ${(props) => props.width};
        height: ${(props) => props.height};
    }
`;

export const SubMenuItemHeaderWrapper = styled.div`
    color: ${(props) => props.color};
    font-family: Arial;
    font-size: 9px;
    text-transform: uppercase;
    margin-top: 8px;
    word-break: break-all;
`;

export const SubMenuItemsWrapper = styled(Col)`  
    background-color: ${(props) => props.background};
    overflow-y: auto;
    max-height: 100vh;
    height: 100vh;
    padding-bottom: 70px;
`;

export const SubMenuItemsContainer = styled.div`
    @media (min-width: 1200px){
        .col-lg-10-percent {
            flex: 0 0 11%;
            max-width: 11%; 
        }
        .col-lg-5-percent {
            flex: 0 0 5.5%;
            max-width: 5.5%; 
        }
        .col-lg-5-percent ~ .col-lg-90-percent {
            flex: 0 0 94.5% !important;
            max-width: 94.5% !important; 
        }
        .col-lg-90-percent {
            flex: 0 0 89%;
            max-width: 89%; 
        }
    }
    overflow: hidden;
    height: 100%;
    display: ${(props) => (props.isVisible ? 'block' : 'none')}
`;

export const ModuleWrapper = styled(Col)`
    max-height: calc(100vh - 60px);
    overflow-x: auto;
    background-color: ${(props) => props.mainareabackgroundcolor};
    .row {
        margin: 0;
    }
`;

export const ModuleContentWrapper = styled.div`
    padding: 12px 15px 15px 15px;
    @media (max-width: 480px) {
        padding: 10px;
    }
`;

export const ModuleHeaderWrapper = styled.div`
    span {
        align-items: center;
    }
    font-weight: bold;
    font-size: 16px;
    line-height: initial;
    color: ${(props) => props.mainAreaTitleColor};
`;

export const ModuleHeaderCloseActionWrapper = styled.span`
    cursor: pointer;
    color: ${(props) => props.mainAreaTitleColor};
    display: inline-flex;
    float: right;
    align-items: end;
    font-size: 12px;
    font-weight: bold;
    i {
        font-size: 18px;   
        margin-right: 5px; 
    }
`;

export const ModuleColumnWrapper = styled.div`
    margin: 10px 20px 10px 0;
    @media (max-width: 480px) {
        margin: 0;
    }
`;

export const SectionWrapper = styled.div`
    margin: 15px 0 30px 0;
`;

export const SectionHeader = styled.div`
    font-family: ${fonts};
    font-size: 12px;
    text-transform: uppercase;
    color: ${(props) => props.color};
`;

export const SearchSectionWrapper = styled.div`
    ${ComboBoxWrapper} {
        width: 100%;
    }
    
`;

export const SectionComboboxWrapper = styled.div`
    width: 88%;
`;

export const SectionActionElementIcon = styled.span`
    padding-right: 3px;
    i {
        padding-right: 8px;
        width: 20px;
        font-size: ${(props) => (props.fs ? props.fs : 15)}px !important;
    }
`;

export const SectionActionElement = styled.div`
    ${(props) => (props.checkDisabled && !props.id ? 'pointer-events: none; opacity: 0.4;' : '')}
    margin: 5px 0 5px 0;
    font-size: 13px;
    display: block;
    a {
        color: black;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
    
    @media (min-width: 480px) {
          font-size: 17px; 
    }    

    @media (min-width: 768px) {
        font-size: 17px; 
    }
      
    @media (min-width: 992px) {    
        font-size: 13px; 
    }
      
    @media (min-width: 1200px) {
        font-size: 13px; 
    }
`;

export const SeparatorBlockWrapper = styled.div`
    min-width: 100px;
    text-align: left;
    color: rgba(0, 0, 0, 0.45);
    white-space: nowrap;
`;

export const SeparatorWrapper = styled.div`
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    align-content: stretch;
    pointer-events: none;
    width: 100%;
    column-gap: 20px;
`;

export const SeparatorContainer = styled.div`
    width: 100%;
    hr {
        margin: 0 0 2px 0 !important;
        padding: 0 !important;
        width: 100% !important;
    }
`;

export const KeyAssessmentValueWrapper = styled.div`
    color: ${(props) => props.color};
    background-color: ${(props) => props.background};
`;

export const KeyAssessmentNameWrapper = styled(Col)`
    margin-bottom: 5px;
`;
export const KeyAssessmentWrapper = styled.div`
    margin: 5px 0px 5px 0px;
`;
