import { colorNames, MOTContainer } from 'app/app.styles';
import styled, { createGlobalStyle } from 'styled-components';

export const DialogWrapper = styled.div`
    .row > * {
        padding-left: 0px !important;
        padding-right: 0px;
    }
`;

export const DialogGlobalStyles = createGlobalStyle`
    .k-window {
        font-size: inherit;
    }

    .k-dialog-wrapper {
        z-index: 12000000 !important;
    }
 
    .k-window-titlebar.k-dialog-titlebar {
        padding-bottom: 8px !important;
    }
    .k-dialog-wrapper .k-dialog {
        overflow: auto;

        .k-dialog-titlebar {
            background-color: unset;
            border: none;
            
            .k-dialog-title {
                color: var(${colorNames.ZenCyan});
            }
        }
    }

    .k-window-content.k-dialog-content {
        display: flex;
        flex-direction: column;
    }
`;

export const DialogContainer = styled.div`
    overflow: auto;
    height: 100%;
    ${MOTContainer}:first-child {
        padding: 15px;
        overflow: auto;
    }
`;

export const DialogButtonWrapper = styled.div`
    padding: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => (props.primary ? `var(${colorNames.ZenCyan})` : '#e4e4e4')};
    color: ${(props) => (props.primary ? `var(${colorNames.ZenFontCyan})` : '#444')};
    ${(props) => (props.enabled ? '' : 'opacity: 0.7; pointer-events: none;')}
`;

export const DialogButtonsWrapper = styled.div`
    .k-dialog-actions.k-actions {
        padding: 0px;
        gap: 0px;
        text-align: center;
        align-items: stretch;
        display: flex;
        border: none;
    }
    
    ${DialogButtonWrapper}:hover {
        cursor: pointer;
        opacity: 0.8;
    }
`;
