import { createSlice, current } from '@reduxjs/toolkit';

export const notificationSlice = createSlice({
  name: 'notificationBar',
  initialState: {
    notifications: [],
  },
  reducers: {
    createAlert: (state, action) => {
        const { message, type, guid } = action.payload;
        state.notifications.push({
            message,
            type,
            guid,
      });
    },
    deleteAlert: (state, action) => {
      const guid = action.payload;
      state.notifications = current(state.notifications).filter((item) => item.guid !== guid);
    },
  },
});

export const { createAlert, deleteAlert } = notificationSlice.actions;
export default notificationSlice.reducer;
