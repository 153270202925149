import { ProtectedRoute } from 'components/auth/protected-route.component';
import { lazy } from 'react';
import { LazyWrapper } from '../routes';

const DeciosionLazy = lazy(() => import('routes/decisionMatrix/decision/decision.component'));
const DeciosionListLazy = lazy(() => import('routes/decisionMatrix/list/decisionMatrixList.component'));
const DeciosionTemplatesListLazy = lazy(() => import('routes/decisionMatrix/templatesList/decisionMatrixTemplatesList.component'));
const DeciosionTemplateLazy = lazy(() => import('routes/decisionMatrix/template/decisionMatrixTemplate.component'));

export const decisionMatrixRoutes = [
    // decision matrix decision
    {
        path: ':clientPrefix/decisionMatrix/decision/:decisionMatrixId',
        component: <LazyWrapper><ProtectedRoute><DeciosionLazy/></ProtectedRoute></LazyWrapper>,
    },
    // decision matrix list
    {
        path: ':clientPrefix/decisionMatrix/list',
        component: <LazyWrapper><ProtectedRoute><DeciosionListLazy/></ProtectedRoute></LazyWrapper>,
    },
    // decision matrix templates list
    {
        path: ':clientPrefix/decisionMatrix/templates',
        component: <LazyWrapper><ProtectedRoute><DeciosionTemplatesListLazy/></ProtectedRoute></LazyWrapper>,
    },
    // decision matrix template
    {
        path: ':clientPrefix/decisionMatrix/template/:dictDecisionMatrixId',
        component: <LazyWrapper><ProtectedRoute><DeciosionTemplateLazy/></ProtectedRoute></LazyWrapper>,
    },
];
