import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { logOut } from 'store/user/user.slice';
import { signOutSelector } from 'store/user/user.selector';
import { toggleSpinner } from 'store/spinner/spinner.slice';
import { getClientPrefix } from 'app/clientPrefix';
import { logError } from 'utils/logger';
import { createAlert } from 'store/notification-bar/notification-bar.slice';
import { createNotificationAction } from 'components/notification-bar/notification-bar.actions';
import { NotificationTypes } from 'components/notification-bar/notification-bar.component';
import store from 'store/store';

export const SignOut = () => {
    const dispatch = useDispatch();
    const signOut = useSelector(signOutSelector);

    useEffect(() => {
        if (signOut) {
            toggleSpinner(true, dispatch);
            const clientPrefix = getClientPrefix(store.getState());
            if (clientPrefix) {
                dispatch(logOut(clientPrefix))
                .then((resp) => {
                    window.location.replace(resp.payload);
                });
            } else {
                logError('Tenant required for login');
                dispatch(createAlert(createNotificationAction(null, NotificationTypes.ERROR)));
            }
        }
    }, [dispatch, signOut]);

    return (null);
};
