import { useSelector } from 'react-redux';
import { menuIdsSelector } from 'store/navigation-bar/navigation-bar.selectors';
import {
    SectionActionElement,
    SectionActionElementIcon,
    SectionHeader,
    SectionWrapper,
} from '../../menu.styles';

export const ActionSection = ({ section, color, code }) => {
    const id = useSelector(menuIdsSelector(code));

    return (
        <SectionWrapper>
            <SectionHeader color={color}>
                {section.name}
            </SectionHeader>
            <div>
                {
                    section.sectionItems.map((sectionItem, index) => <SectionActionElement checkDisabled={true} id={id} key={index}>
                        <SectionActionElementIcon className='d-inline-block'>
                            <i className={`${sectionItem.iconFontAwesome}`}></i>
                        </SectionActionElementIcon>
                        <span>
                            <a href={sectionItem.url.replace('__IDPLACEHOLDER__', id)}>{sectionItem.name}</a>
                        </span>
                    </SectionActionElement>)
                }
            </div>
        </SectionWrapper>
    );
};
