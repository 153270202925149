import { Row } from 'react-bootstrap';
import { getIcons } from 'store/navigation-bar/navigation-bar.slice';
import { SubMenuItemsContainer, SubMenuItemsWrapper } from '../menu.styles';
import { Module } from './module.component';
import { SubMenuItem } from './sub-menu-item.component';

export const SubMenu = ({
    isVisible,
    modules,
    selectedModule,
    verticalBarIconPadding,
    verticalBarBackgroundColor,
    verticalBarIconHeight,
    verticalBarIconWidth,
    verticalMenuIconColour,
    verticalMenuTextColour,
    verticalBarIconOnHooverBackgroundColor,
    useTrippleBlockVerticalBar,
    useDuoBlockVerticalBar,
    useSingleBlockVerticalBar,
    mainAreaBackgroundColor,
    mainAreaTitleColor,
    mainAreaSectionHeaderColor,
    closeMenu,
    editUserUrl,
    documentPreviewUrl,
    clientLogoHref,
}) => {
    let lg;
    let lgClass = '';
    if (useTrippleBlockVerticalBar) {
        lg = 2;
    } else if (useDuoBlockVerticalBar) {
        lgClass = 'col-lg-10-percent';
    } else if (useSingleBlockVerticalBar) {
        lgClass = 'col-lg-5-percent';
    } else {
        lgClass = 'col-lg-10-percent';
    }

    const icons = getIcons();
    return (
        <SubMenuItemsContainer isVisible={isVisible}>
            <Row className='m-0'>
                <SubMenuItemsWrapper background={verticalBarBackgroundColor} className={`px-0 ${lgClass}`} lg={lg} md={2} sm={2} xs={3}>
                    <Row className='m-0'>
                    {
                        modules.map((module, index) => <SubMenuItem
                            key={index}
                            icons={icons}
                            clientLogoHref={clientLogoHref}
                            isActive={selectedModule === module.codeName}
                            module={module}
                            verticalBarIconHeight={verticalBarIconHeight}
                            verticalBarIconWidth={verticalBarIconWidth}
                            verticalMenuIconColour={verticalMenuIconColour}
                            verticalMenuTextColour={verticalMenuTextColour}
                            verticalBarIconPadding={verticalBarIconPadding}
                            verticalBarIconOnHooverBackgroundColor={verticalBarIconOnHooverBackgroundColor}
                            useTrippleBlockVerticalBar={useTrippleBlockVerticalBar}
                            useDuoBlockVerticalBar={useDuoBlockVerticalBar}
                            useSingleBlockVerticalBar={useSingleBlockVerticalBar}
                        />)
                    }
                    </Row>
                </SubMenuItemsWrapper>
                <Module
                    closeMenu={closeMenu}
                    selectedModule={selectedModule}
                    mainAreaSectionHeaderColor={mainAreaSectionHeaderColor}
                    useTrippleBlockVerticalBar={useTrippleBlockVerticalBar}
                    modules={modules}
                    mainAreaBackgroundColor={mainAreaBackgroundColor}
                    mainAreaTitleColor={mainAreaTitleColor}
                    editUserUrl={editUserUrl}
                    documentPreviewUrl={documentPreviewUrl}
                />
            </Row>
        </SubMenuItemsContainer>
    );
};
